<template>
	<!-- bug: when creating a new form, and looking at a question's options, the options are not pre populated -->
	<div class="flex flex-col px-5 py-6 bg-gray-200 text-gray-700 border-b">
		<label class="text-xs text-left">Options</label>
		<div class="mt-2">
				<div v-for="(option, index) in options" :key="index" class=" mb-5 rounded-lg shadow-sm bg-white max-w-xl m-auto flex flex-col">
					<div class="flex justify-between items-center px-5 py-3 text-gray-700 border-b">
						<svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
							<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 8h16M4 16h16" />
						</svg>
						<button v-if="getIsActiveFormEditable" :disabled="!getIsActiveFormEditable" @click="deleteOption(option)">
							<svg
								class="h-4 w-4"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
							>
								<path
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
									d="M6 18L18 6M6 6l12 12"
								/>
							</svg>
						</button>
					</div>
					<div class="px-5 my-5 flex flex-col">
						<label class="text-xs text-left">Option Type</label>
						<div class="mt-2 relative rounded-md shadow-sm">
							<div class="relative">
								<select
								disabled
								v-model="option.type"
									class="appearance-none h-full rounded border block appearance-none w-full bg-white border-gray-500 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
								>
									<option v-for="(type, index) in list" :key="`type${index}`">{{type}}</option>
								</select>
							</div>
						</div>
						<OptionFieldInteger v-if="option.type === 'integer'" @validate="checkValid" :currentlyEditing="option" :index="index"/>
						<OptionFieldCurrency v-if="option.type === 'currency'"  @validate="checkValid" :currentlyEditing="option" :index="index"/>
						<OptionFieldText v-if="option.type === 'text'" @validate="checkValid" :currentlyEditing="option" :index="index"/>
						<OptionFieldTextArea v-if="option.type === 'textarea'" @validate="checkValid" :currentlyEditing="option" :index="index"/>
						<OptionFieldPassword v-if="option.type === 'password'"  @validate="checkValid" :currentlyEditing="option" :index="index"/>
						<OptionFieldShowImage v-if="option.type === 'show_image'"  @validate="checkValid" :currentlyEditing="option" :index="index"/>
						<OptionFieldButton v-if="option.type === 'button'" @validate="checkValid" :currentlyEditing="option" :index="index"/>
						<OptionFieldImageNotes v-if="option.type === 'image_notes'" @validate="checkValid" :currentlyEditing="option" :index="index"/>
						<OptionFieldAddress v-if="option.type === 'address'" @validate="checkValid" :currentlyEditing="option" :index="index"/>
						<OptionFieldScale v-if="option.type === 'scale'"  @validate="checkValid" :currentlyEditing="option" :index="index"/>
						<OptionFieldSignature v-if="option.type === 'signature'" @validate="checkValid" :currentlyEditing="option" :index="index"/>
						<OptionFieldDateTime v-if="option.type === 'datetime'"  @validate="checkValid" :currentlyEditing="option" :index="index"/>
						<OptionFieldCalculatedField v-if="option.type === 'calculated'"  @validate="checkValid" :currentlyEditing="option" :index="index"/>
						<OptionFieldSelectSingle v-if="option.type === 'select' && option.config.multiple === false"  @validate="checkValid" :currentlyEditing="option" :index="index"/>
						<OptionFieldSelectMultiple v-if="option.type === 'select' && option.config.multiple === true" @validate="checkValid" :currentlyEditing="option" :index="index"/>
						<OptionFieldDropDown v-if="option.type === 'dropdown'"  @validate="checkValid" :currentlyEditing="option" :index="index"/>
						<OptionFieldPhoneNumber v-if="option.type === 'phone_number'" @validate="checkValid" :currentlyEditing="option" :index="index"/>
						<OptionFieldNote v-if="option.type === 'note'"  @validate="checkValid" :currentlyEditing="option" :index="index"/>
					</div>
				</div>
				<button
					v-if="options.length && options.length > 0"
					@click="saveOption()"
					:disabled="!getIsActiveFormEditable"
					class="capitalize my-3 mx-auto px-3 py-1 block text-white rounded-md text-sm focus:outline-none bg-red-600 hover:bg-red-500 disabled:bg-red-200">save options</button>
				<div v-if="addingNewOption" class=" mb-5 rounded-lg shadow-sm bg-white max-w-xl m-auto flex flex-col">
					<div class="flex justify-between items-center px-5 py-3 text-gray-700 border-b">
						<svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
							<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 8h16M4 16h16" />
						</svg>
						<button @click="removeNewOption()" :disabled="!getIsActiveFormEditable">
							<svg
								class="h-4 w-4"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
							>
								<path
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
									d="M6 18L18 6M6 6l12 12"
								/>
							</svg>
						</button>
					</div>
					<div class="px-5 my-5 flex flex-col">
						<label class="text-xs text-left">Option Type</label>
						<div class="mt-2 relative rounded-md shadow-sm">
							<div class="relative">
								<!-- maybe do v-model newType, watch newType to filter/change whichever names need changing, then set newOption.type = whatever -->
								<select
								:disabled="!getIsActiveFormEditable"
								v-model="selectedType"
									class="appearance-none h-full rounded border block appearance-none w-full bg-white border-gray-500 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
								>
									<option v-for="(newType, index) in selectAType" :key="`newType${index}`">{{newType}}</option>
								</select>
							</div>
						</div>
						<!-- all fields components -->
						<!-- pass prop of option into child :currentlyEditing = "option" -->
						<OptionFieldInteger v-if="newOption.type === 'integer'" @validate="checkValidNew" :index="index"/>
						<OptionFieldCurrency v-if="newOption.type === 'currency'"  @validate="checkValidNew" :index="index"/>
						<OptionFieldText v-if="newOption.type === 'text'" @validate="checkValidNew" addNew="true" :index="index"/>
						<OptionFieldTextArea v-if="newOption.type === 'textarea'" @validate="checkValidNew" :index="index"/>
						<OptionFieldPassword v-if="newOption.type === 'password'"  @validate="checkValidNew" :index="index"/>
						<OptionFieldShowImage v-if="newOption.type === 'show_image'"  @validate="checkValidNew" :index="index"/>
						<OptionFieldButton v-if="newOption.type === 'button'" @validate="checkValidNew" :index="index"/>
						<OptionFieldImageNotes v-if="newOption.type === 'image_notes'" @validate="checkValidNew" :index="index"/>
						<OptionFieldAddress v-if="newOption.type === 'address'" @validate="checkValidNew" :index="index"/>
						<OptionFieldScale v-if="newOption.type === 'scale'"  @validate="checkValidNew" :index="index"/>
						<OptionFieldSignature v-if="newOption.type === 'signature'" @validate="checkValidNew" :index="index"/>
						<OptionFieldDateTime v-if="newOption.type === 'datetime'"  @validate="checkValidNew" :index="index"/>
						<OptionFieldCalculatedField v-if="newOption.type === 'calculated'"  @validate="checkValidNew" :index="index"/>
						<OptionFieldSelectSingle v-if="newOption.type === 'select' && selectedSelectType === 'single'"  @validate="checkValidNew" :index="index"/>
						<OptionFieldSelectMultiple v-if="newOption.type === 'select' && selectedSelectType === 'multiple'" @validate="checkValidNew" :index="index"/>
						<OptionFieldDropDown v-if="newOption.type === 'dropdown'"  @validate="checkValidNew" :index="index"/>
						<OptionFieldPhoneNumber v-if="newOption.type === 'phone_number'" @validate="checkValidNew" :index="index"/>
						<OptionFieldNote v-if="newOption.type === 'note'"  @validate="checkValidNew" :index="index"/>
					</div>
					<button
						:disabled="!newOptionGood || !getIsActiveFormEditable"
						@click="saveNewOption()"
						:class="newOptionGood ? 'bg-red-600 hover:bg-red-500' : 'bg-red-200'"
						class="capitalize mt-3 px-3 py-1 text-white rounded-md text-sm  focus:outline-none">save new option</button>
				</div>
				<button
					v-if="!addingNewOption"
					:disabled="!checkOriginals || !getIsActiveFormEditable"
					@click="addNewOption()"
					:class="!checkOriginals ? 'bg-red-200': 'bg-red-600 hover:bg-red-500'"
					class="capitalize mt-3 px-3 py-1 text-white rounded-md text-sm focus:outline-none disabled:bg-red-200 disabled:bg-red-200">add option</button>
		</div>
	</div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { defineComponent } from 'vue';
import OptionFieldInteger from '../optionFields/optionFieldInteger.vue'
import OptionFieldCurrency from '../optionFields/optionFieldCurrency.vue'
import OptionFieldText from '../optionFields/optionFieldText.vue'
import OptionFieldTextArea from '../optionFields/optionFieldTextArea.vue'
import OptionFieldPassword from '../optionFields/optionFieldPassword.vue'
import OptionFieldShowImage from '../optionFields/optionFieldShowImage.vue'
import OptionFieldButton from '../optionFields/optionFieldButton.vue'
import OptionFieldImageNotes from '../optionFields/optionFieldImageNotes.vue'
import OptionFieldAddress from '../optionFields/optionFieldAddress.vue'
import OptionFieldScale from '../optionFields/optionFieldScale.vue'
import OptionFieldSignature from '../optionFields/optionFieldSignature.vue'
import OptionFieldDateTime from '../optionFields/optionFieldDateTime.vue'
import OptionFieldCalculatedField from '../optionFields/optionFieldCalculatedField.vue'
import OptionFieldSelectSingle from '../optionFields/optionFieldSelectSingle.vue'
import OptionFieldSelectMultiple from '../optionFields/optionFieldSelectMultiple.vue'
import OptionFieldDropDown from '../optionFields/optionFieldDropDown.vue'
import OptionFieldPhoneNumber from '../optionFields/optionFieldPhoneNumber.vue'
import OptionFieldNote from '../optionFields/optionFieldNote.vue'

export default defineComponent ({
	name: 'ModalQuestionEditOptions',
	props: {},
	data: () => {
		return {
			options: [],
			newOption: {},
			addingNewOption: false,
			newType: '',
			selectedType: '',
			selectAType: [
				'integer',
				'currency',
				'text',
				'textarea',
				'password',
				'show image',
				'button',
				'image notes',
				'address',
				'scale',
				'signature',
				'datetime',
				'calculated',
				'select (multiple)',
				'select (single)',
				'dropdown',
				'phone number',
				'note'
			],
			list: [
				'integer',
				'currency',
				'text',
				'textarea',
				'password',
				'show_image',
				'button',
				'image_notes',
				'address',
				'scale',
				'signature',
				'datetime',
				'calculated',
				'select',
				'select',
				'dropdown',
				'phone_number',
				'note'
			],
			selectedSelectType: '',
			newOptionGood: false,
			originalOptionsGood: true,
			checkOriginals: true
		}
	},
	components: {
		OptionFieldInteger,
		OptionFieldCurrency,
		OptionFieldText,
		OptionFieldTextArea,
		OptionFieldPassword,
		OptionFieldShowImage,
		OptionFieldButton,
		OptionFieldImageNotes,
		OptionFieldAddress,
		OptionFieldScale,
		OptionFieldSignature,
		OptionFieldDateTime,
		OptionFieldCalculatedField,
		OptionFieldSelectSingle,
		OptionFieldSelectMultiple,
		OptionFieldDropDown,
		OptionFieldPhoneNumber,
		OptionFieldNote
	},
	computed: {
		...mapGetters([
			'getActiveOptions',
			'getWorkingOption',
			'getModifyingOriginalOptions',
			'getIsActiveFormEditable'
		])
	},
	watch: {
		selectedType(value) {
			if (value === 'select (single)') {
				this.selectedSelectType = 'single'
				this.newOption.type = 'select'
			} else if (value === 'select (multiple)') {
				this.selectedSelectType = 'multiple'
				this.newOption.type = 'select'
			} else if (value === 'show image') {
				this.newOption.type = 'show_image'
			} else if (value === 'image notes') {
				this.newOption.type = 'image_notes'
			} else if (value === 'phone number') {
				this.newOption.type = 'phone_number'
			} else {
				this.newOption.type = value
			}
		},
		getModifyingOriginalOptions: {
			deep: true,
			handler () {
				let check = true
				if (this.getModifyingOriginalOptions.length) {
					for (let x = 0; x < this.getModifyingOriginalOptions.length; x++) {
						check = this.getModifyingOriginalOptions[x].check
						if (x === this.getModifyingOriginalOptions.length - 1) {
							this.checkOriginals = check
						}
					}
				} else {
					this.checkOriginals = check
				}
			}
		},
	},
	created () {
		this.options = this.getActiveOptions
	},
	mounted () {},
	methods: {
		...mapMutations([
			'setModalContent',
			'setShowModal',
			'closeModal',
			'setActiveOptions',
			'setWorkingOption',
			'setShowSnackbar',
			'setShowSuccessSnackbar'
		]),
		...mapActions([
			'removeOption',
			'createOption',
			'listQuestionOptions',
			'updateOption'
		]),
		addNewOption: function () {
			this.addingNewOption = true
		},
		saveNewOption: function () {
			this.createOption(this.getWorkingOption).then((res1) => {
				if (res1.status === 'error') {
					this.setShowSnackbar(res1.msg)
				} else {
					this.setWorkingOption(null)
					this.listQuestionOptions().then((res2) => {
						if (res2.status === 'error') {
							this.setShowSnackbar(res2.msg)
						} else {
							this.removeNewOption()
							this.setActiveOptions(res2.payload)
							this.setShowSuccessSnackbar(res2.status)
							this.options = this.getActiveOptions
						}
					})
				}
			})
		},
		saveOption: function () {
			for (let x = 0; x < this.getModifyingOriginalOptions.length; x++) {
				this.updateOption(this.getModifyingOriginalOptions[x]).then((res) => {
					if (res.status === 'error') {
						this.setShowSnackbar(res.msg)
					} else {
						this.setShowSuccessSnackbar(res.status)
					}
				})
				if (x === this.getModifyingOriginalOptions.length - 1) {
					this.listQuestionOptions().then((res1) => {
						if (res1.status === 'error') {
							this.setShowSnackbar(res1.msg)
						} else {
							this.setActiveOptions(res1.payload)
							this.setShowSuccessSnackbar(res1.status)
							this.options = this.getActiveOptions
							this.addingNewOption = false
						}
					})
				}
			}
		},
		deleteOption: function (option) {
			this.removeOption(option.id).then((res) => {
				if (res.status === 'error') {
					this.setShowSnackbar(res.msg)
				} else {
					this.listQuestionOptions().then((res1) => {
						this.setActiveOptions(res1.payload)
						this.options = this.getActiveOptions
						this.setShowSuccessSnackbar(res.status)
					})
				}
			})
		},
		removeNewOption: function () {
			this.newOption = {}
			this.addingNewOption = false
			this.selectedType = ''
		},
		checkValid: function (value) {
			this.originalOptionsGood = value
			// this.originalOptionsGood = true
		},
		checkValidNew: function (value) {
			this.newOptionGood = value
		}
	}
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.toggle__dot {
  top: -.25rem;
  left: -.25rem;
  transition: all 0.3s ease-in-out;
}

input:checked ~ .toggle__dot {
  transform: translateX(100%);
  background-color: #dc2626;
}
</style>
