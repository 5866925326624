import { createApp } from 'vue'
import App from './App.vue'
import store from "./store/store"
import '@/assets/styles/tailwind.css';

// import { applyPolyfills, defineCustomElements } from '@unoapp/forms-engine-component/loader'

// createApp(App).mount('#app')
import router from './router/index'

const app = createApp(App)
app.use(router)
app.use(store)

// app.config.isCustomElement = tag => tag.startsWith('test-')
// applyPolyfills().then(() => {
// 	defineCustomElements()
// })
// app.config.devtools = true

app.mount('#app')
