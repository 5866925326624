<template>
	<div class="w-full h-full flex flex-col">
		<ul v-if="getSections.length && getSections" class="w-full" key="sections-list">
			<!-- Items Section -->
			<!-- v-for form section names -->
			<li v-for="(section, index) in getSections" :key="`section${index}`">
				<!-- :class for getActiveSectionId -->
				<a @click="setActiveSectionId(section.id)" :class="getActiveSectionId === section.id ? 'bg-gray-100' : ''" class="h-16 px-6 flex justify-between items-center w-full
				focus:text-orange-500 flex-shrink-0 hover:bg-gray-100 cursor-pointer">
					<svg
						class="h-5 w-5"
						xmlns="http://www.w3.org/2000/svg"
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						stroke="currentColor"
						stroke-width="2"
						stroke-linecap="round"
						stroke-linejoin="round">
						<polyline
							points="22 12 16 12 14 15 10 15 8 12 2 12"></polyline>
						<path
							d="M5.45 5.11L2 12v6a2 2 0 0 0 2 2h16a2 2 0 0 0
							2-2v-6l-3.45-6.89A2 2 0 0 0 16.76 4H7.24a2 2 0 0
							0-1.79 1.11z"></path>
					</svg>
					<span :class="!getMainSidebarOpen ? 'hidden' : 'flex-grow'" class="capitalize" style="overflow-wrap:anywhere" >{{section.title}}</span>
				</a>
			</li>
		</ul>
		<ul v-else key="no-sections-list"> no sections</ul>
		<div class="mt-auto h-32 flex items-center w-11/12 self-center flex-col border-t">
			<!-- getIsEdit && getIsActiveFormEditable && getSections.length > 0 say "view section" -->
			<button v-if="getSections.length > 0" @click="editSectionConfig" class="w-full mt-3 py-1 border border-red-600 text-red-600 rounded-md text-sm hover:bg-red-100 focus:outline-none flex justify-center items-center">
				<svg :class="getMainSidebarOpen ? 'hidden' : 'flex-grow'"  class="w-6 h-6 inline-block mx-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="#dc2626">
					<path fill="none" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
				</svg>
				<span v-if="getIsActiveFormEditable" key="editsection" :class="!getMainSidebarOpen ? 'hidden' : 'flex-grow'"  class="text-base capitalize">edit section</span>
				<span v-else key="viewsection" :class="!getMainSidebarOpen ? 'hidden' : 'flex-grow'"  class="text-base capitalize">view section</span>
			</button>
			<button v-if="(getIsEdit && getIsActiveFormEditable) || getIsCreatingNewForm" @click="createNewSection" :disabled="getIsEdit && !getIsActiveFormEditable"
			class="w-full mt-3 py-1 bg-red-600 text-white rounded-md text-sm hover:bg-red-500 focus:outline-none flex justify-center items-center">
				<svg :class="getMainSidebarOpen ? 'hidden' : 'flex-grow'"  class="w-6 h-6 inline-block mx-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
					<path fill="none" stroke-width="2" d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
				</svg>
			<!-- class="h-16 w-10 mx-auto flex flex justify-center items-center
			w-full focus:text-orange-500  focus:outline-none" -->
			<!-- 			:class="getIsEdit && !getIsActiveFormEditable ? '': 'hover:bg-red-200'" -->
				<span :class="!getMainSidebarOpen ? 'hidden' : 'flex-grow'"  class="text-base capitalize">create section</span>
			</button>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
export default {
	name: 'SidebarNew',
	props: {},
	data() {
		return {}
	},
	components: {},
	computed: {
		...mapGetters([
			'getSections',
			'getMainSidebarOpen',
			'getActiveSectionId',
			'getIsEdit',
			'getIsActiveFormEditable',
			'getIsActiveFormEditable',
			'getIsCreatingNewForm'
		])
	},
	watch: {
		getSections () {
			this.$forceUpdate()
		},
	},
	created () {
		this.getFormSections().then((res) => {
			console.log('form sections')
			console.log(res)
			console.log(this.getIsActiveFormEditable)
			// if no sections, and can still edit form, immediately show create a section modal:
			if ((res === false || res.status === 'error') && this.getIsActiveFormEditable) {
				this.createNewSection()
			}
		})
	},
	mounted () {},
	methods: {
		...mapMutations([
			'setSections',
			'setShowModal',
			'setModalContent',
			'setActiveSectionId',
			'setModifyingSection'
		]),
		...mapActions([
			'getFormSections'
		]),
		createNewSection: function () {
			this.setShowModal(true)
			this.setModalContent('selectSectionLayout')
		},
		editSectionConfig: function () {
			for (let x = 0; x < this.getSections.length; x++) {
				if (this.getSections[x].id === this.getActiveSectionId) {
					this.setModifyingSection(this.getSections[x])
					this.setModalContent('sectionEdit')
					this.setShowModal(true)
				}
			}
		},
	}
};
</script>
