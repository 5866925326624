<template>
	<div class="w-full fixed bottom-4 z-50">
		<!-- pass prop that is error=true/false to determine red/green color -->
		<div class="bg-red-600 block w-9/12 mx-auto bg-opacity-70 shadow-lg rounded-xl">
			<div class="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8 flex items-center justify-between w-full">
				<div class="flex items-center w-9/12">
					<div class="w-full">
						<p class="ml-3 font-medium text-white text-left">
							{{getSnackbarMessage}}
							<!-- <span>
							</span> -->
						</p>
					</div>
				</div>
				<div class="order-2 flex-shrink-0 sm:order-3 sm:ml-3">
					<button @click="dismissSnackbar" type="button" class="-mr-1 flex p-2 rounded-full hover:bg-red-700 focus:outline-none sm:-mr-2">
						<span class="sr-only">Dismiss</span>
						<svg class="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
							<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
						</svg>
					</button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import { defineComponent } from 'vue';
export default defineComponent ({
	name: 'Snackbar',
	props: ['type'],
	data: () => {
		return {}
	},
	components: {},
	computed: {
		...mapGetters([
			'getShowSnackbar',
			'getSnackbarMessage'
		])
	},
	watch: {
	},
	created () {
	},
	mounted () {
	},
	methods: {
		...mapMutations([
			'dismissSnackbar'
		])
	}
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
