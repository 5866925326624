<template>
	<div>
		<div class="flex flex-col  pb-6 text-gray-700">
			<div class="mt-2 relative rounded-md shadow-sm">
				<div class="relative flex flex-col mt-2">
					<label class="text-xs text-left">Help Text</label>
					<input
						type="text"
						v-model="help_text"
						:disabled="!getIsActiveFormEditable"
						class="mt-2 form-input w-full px-3 py-2 appearance-none rounded-md focus:border-indigo-600 disabled:opacity-60"
					/>
				</div>
				<div class="relative flex flex-col mt-2">
					<label class="text-xs text-left">Help Text</label>
					<select
					v-model="type"
					:disabled="!getIsActiveFormEditable"
					class="mt-2 appearance-none h-full rounded border block appearance-none w-full bg-white border-gray-500 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 disabled:opacity-60"
					>
						<option v-for="(option, index) in types" :key="`option${index}`">{{option}}</option>
					</select>
				</div>
				<div v-if="type === 'dateonly' || type === 'datetime'" class="relative flex flex-col mt-2">
					<label class="text-xs text-left">Minimum Year</label>
					<input
						type="number"
						v-model="min_year"
						:disabled="!getIsActiveFormEditable"
						class="mt-2 form-input w-full px-3 py-2 appearance-none rounded-md focus:border-indigo-600 disabled:opacity-60"
					/>
				</div>
				<div v-if="type === 'dateonly' || type === 'datetime'" class="relative flex flex-col mt-2">
					<label class="text-xs text-left">Maximum Year</label>
					<input
						type="number"
						v-model="max_year"
						:disabled="!getIsActiveFormEditable"
						class="mt-2 form-input w-full px-3 py-2 appearance-none rounded-md focus:border-indigo-600 disabled:opacity-60"
					/>
				</div>
				<div class="relative flex flex-col mt-2">
					<label class="text-xs text-left">Ref Key</label>
					<input
						type="text"
						v-model="ref_key"
						:disabled="!getIsActiveFormEditable"
						class="mt-2 form-input w-full px-3 py-2 appearance-none rounded-md focus:border-indigo-600 disabled:opacity-60"
					/>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { defineComponent } from 'vue';

export default defineComponent ({
	name: 'OptionFieldDateTime',
	props: {
		clear: {
			type: Boolean
		},
		currentlyEditing: {
			type: Object
		},
		addNew: {
			type: Boolean
		},
		index: {
			type: Number
		}
	},
	data: () => {
		return {
			help_text: '',
			ref_key: '',
			type: 'timeonly',
			types: [
				'dateonly',
				'timeonly',
				'datetime'
			],
			min_year: 0,
			max_year: 0
		}
	},
	components: {},
	computed: {
		...mapGetters([
			'getActiveQuestionId',
			'getIsEdit',
			'getIsActiveFormEditable'
		])
	},
	watch: {
		help_text(value) {
			this.updateHelpText(value)
		},
		ref_key(value) {
			this.updateRefKey(value)
		},
		type(value) {
			this.updateType(value)
		},
		min_year(value) {
			this.updateMinYear(value)
		},
		max_year(value) {
			this.updateMaxYear(value)
		},
		clear(value) {
			if (value === true) {
				this.help_text = ''
				this.ref_key = ''
			}
		}
	},
	created () {
		this.$emit('validate', false)
		if (this.getIsEdit) {
			if (!this.addNew && this.currentlyEditing) {
				this.help_text = this.currentlyEditing.help_text
				this.type = this.currentlyEditing.config.type
				this.ref_key = this.currentlyEditing.ref
				if (this.currentlyEditing.config.type === 'dateonly' || this.currentlyEditing.config.type === 'datetime') {
					this.max_year = this.currentlyEditing.config.max_year
					this.min_year = this.currentlyEditing.config.min_year
				}
				this.$emit('validate', true)
			}
		}
	},
	mounted () {},
	methods: {
		...mapMutations([
			'setModalContent',
			'setShowModal',
			'closeModal',
			'setWorkingOption',
			'modifyActiveOption'
		]),
		...mapActions([
		]),
		updateHelpText: function (value) {
			let data = {}
			if (this.type === 'dateonly' || this.type === 'datetime') {
				data = {
					'question_id': this.getActiveQuestionId,
					'type': 'datetime',
					'order': 1,
					'help_text': value,
					'config': {
						'type': this.type,
						'min_year': +this.min_year,
						'max_year': +this.max_year
					},
					'ref': this.ref_key
				}
				if (+this.min_year < +this.max_year) {
					this.$emit('validate', true)
				} else {
					this.$emit('validate', false)
				}
			} else {
				data = {
					'question_id': this.getActiveQuestionId,
					'type': 'datetime',
					'order': 1,
					'help_text': value,
					'config': {
						'type': this.type
					},
					'ref': this.ref_key
				}
				this.$emit('validate', true)
			}
			this.setWorkingOption(data)
			if (this.getIsEdit && this.currentlyEditing) {
				data.id = this.currentlyEditing.id
				if (+this.min_year < +this.max_year) {
					data.check = true
				} else {
					data.check = false
				}
				let info = {
					'index': this.index,
					'modified': data
				}
				this.modifyActiveOption(info)
			}
		},
		updateType: function (value) {
			let data = {}
			if (this.type === 'dateonly' || this.type === 'datetime') {
				data = {
					'question_id': this.getActiveQuestionId,
					'type': 'datetime',
					'order': 1,
					'help_text': this.help_text,
					'config': {
						'type': value,
						'min_year': +this.min_year,
						'max_year': +this.max_year
					},
					'ref': this.ref_key
				}
				if (+this.min_year < +this.max_year) {
					this.$emit('validate', true)
				} else {
					this.$emit('validate', false)
				}
			} else {
				data = {
					'question_id': this.getActiveQuestionId,
					'type': 'datetime',
					'order': 1,
					'help_text': this.help_text,
					'config': {
						'type': value
					},
					'ref': this.ref_key
				}
				this.$emit('validate', true)
			}
			this.setWorkingOption(data)
			if (this.getIsEdit && this.currentlyEditing) {
				data.id = this.currentlyEditing.id
				if (+this.min_year < +this.max_year) {
					data.check = true
				} else {
					data.check = false
				}
				let info = {
					'index': this.index,
					'modified': data
				}
				this.modifyActiveOption(info)
			}
		},
		updateMinYear: function (value) {
			let data = {}
			if (this.type === 'dateonly' || this.type === 'datetime') {
				data = {
					'question_id': this.getActiveQuestionId,
					'type': 'datetime',
					'order': 1,
					'help_text': this.help_text,
					'config': {
						'type': this.type,
						'min_year': +value,
						'max_year': +this.max_year
					},
					'ref': this.ref_key
				}
				if (+value < +this.max_year) {
					this.$emit('validate', true)
				} else {
					this.$emit('validate', false)
				}
			} else {
				data = {
					'question_id': this.getActiveQuestionId,
					'type': 'datetime',
					'order': 1,
					'help_text': this.help_text,
					'config': {
						'type': this.type
					},
					'ref': this.ref_key
				}
				this.$emit('validate', true)
			}
			this.setWorkingOption(data)
			if (this.getIsEdit && this.currentlyEditing) {
				data.id = this.currentlyEditing.id
				if (+value < +this.max_year) {
					data.check = true
				} else {
					data.check = false
				}
				let info = {
					'index': this.index,
					'modified': data
				}
				this.modifyActiveOption(info)
			}
		},
		updateMaxYear: function (value) {
			let data = {}
			if (this.type === 'dateonly' || this.type === 'datetime') {
				data = {
					'question_id': this.getActiveQuestionId,
					'type': 'datetime',
					'order': 1,
					'help_text': this.help_text,
					'config': {
						'type': this.type,
						'min_year': +this.min_year,
						'max_year': +value
					},
					'ref': this.ref_key
				}
				if (+this.min_year < +value) {
					this.$emit('validate', true)
				} else {
					this.$emit('validate', false)
				}
			} else {
				data = {
					'question_id': this.getActiveQuestionId,
					'type': 'datetime',
					'order': 1,
					'help_text': this.help_text,
					'config': {
						'type': this.type
					},
					'ref': this.ref_key
				}
				this.$emit('validate', true)
			}
			this.setWorkingOption(data)
			if (this.getIsEdit && this.currentlyEditing) {
				data.id = this.currentlyEditing.id
				if (+this.min_year < +value) {
					data.check = true
				} else {
					data.check = false
				}				let info = {
					'index': this.index,
					'modified': data
				}
				this.modifyActiveOption(info)
			}
		},
		updateRefKey: function (value) {
			let data = {}
			if (this.type === 'dateonly' || this.type === 'datetime') {
				data = {
					'question_id': this.getActiveQuestionId,
					'type': 'datetime',
					'order': 1,
					'help_text': this.help_text,
					'config': {
						'type': this.type,
						'min_year': +this.min_year,
						'max_year': +this.max_year
					},
					'ref': value
				}
				if (+this.min_year < +this.max_year) {
					this.$emit('validate', true)
				} else {
					this.$emit('validate', false)
				}
			} else {
				data = {
					'question_id': this.getActiveQuestionId,
					'type': 'datetime',
					'order': 1,
					'help_text': this.help_text,
					'config': {
						'type': this.type
					},
					'ref': value
				}
				this.$emit('validate', true)
			}
			this.setWorkingOption(data)
			if (this.getIsEdit && this.currentlyEditing) {
				data.id = this.currentlyEditing.id
				if (+this.min_year < +this.max_year) {
					data.check = true
				} else {
					data.check = false
				}
				let info = {
					'index': this.index,
					'modified': data
				}
				this.modifyActiveOption(info)
			}
		}
	}
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.toggle__dot {
  top: -.25rem;
  left: -.25rem;
  transition: all 0.3s ease-in-out;
}

input:checked ~ .toggle__dot {
  transform: translateX(100%);
  background-color: #dc2626;
}
</style>
