<template>
  <div class="hello1">
		<div class="w-full">
			<div class="mt-4">
				<div class="mt-4">
					<!-- overflow-hidden  -->
					<div class="w-full bg-white shadow-md rounded-md border">
						<form @submit.prevent="compileForm">
							<h1 class="flex px-5 py-6 text-gray-700 text-left border-b">{{getActiveForm.title}}</h1>
							<div class="flex flex-col px-5 py-6 text-gray-700">
								<label class="text-xs text-left">is public</label>
								<div class="flex items-center w-full mb-2 mt-4">
									<label class="flex items-center cursor-pointer">
										<div class="relative">
											<input v-model="form.is_public" :disabled="!getIsActiveFormEditable" type="checkbox" class="hidden" />
											<div class="toggle__line w-10 h-4 bg-gray-400 rounded-full shadow-inner"></div>
											<div class="toggle__dot absolute w-6 h-6 bg-white rounded-full shadow inset-y-0 left-0"></div>
										</div>
									</label>
								</div>
							</div>
							<!-- <div class="flex flex-col px-5 pb-6 text-gray-700">
								<label class="text-xs text-left">Progress</label>
								<div class="mt-2 relative rounded-md shadow-sm">
									<div class="relative">
										<select
										v-model="form.progress"
										:disabled="!getIsActiveFormEditable"
											class="appearance-none h-full rounded border block appearance-none w-full bg-white border-gray-500 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
										>
											<option>percentage</option>
										</select>
									</div>
								</div>
							</div> -->
							<!-- <div class="flex flex-col px-5 pb-6 text-gray-700">
								<label class="text-xs text-left">Rendering Type</label>
								<div class="mt-2 relative rounded-md shadow-sm">
									<div class="relative">
										<select
										v-model="form.rendering_type"
										:disabled="!getIsActiveFormEditable"
											class="appearance-none h-full rounded border block appearance-none w-full bg-white border-gray-500 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
										>
											<option>all</option>
										</select>
									</div>
								</div>
							</div> -->
							<div class="flex flex-col px-5 pb-6 text-gray-700">
								<label class="text-xs text-left">Submissions Per User</label>
								<div class="mt-2 relative rounded-md shadow-sm">
									<input v-model="form.submissions_per_user" :disabled="!getIsActiveFormEditable" type="number" class="form-input w-full px-3 py-2 appearance-none rounded-md focus:border-indigo-600 disabled:opacity-60"/>
								</div>
							</div>
							<div class="flex flex-col px-5 pb-6 text-gray-700">
								<label class="text-xs text-left">Show Welcome Page</label>
								<div class="flex items-center w-full mb-2 mt-4">
									<label class="flex items-center cursor-pointer">
										<div class="relative">
											<input v-model="form.show_welcome_page" :disabled="!getIsActiveFormEditable" type="checkbox" class="hidden" />
											<div class="toggle__line w-10 h-4 bg-gray-400 rounded-full shadow-inner"></div>
											<div class="toggle__dot absolute w-6 h-6 bg-white rounded-full shadow inset-y-0 left-0"></div>
										</div>
									</label>
								</div>
								<div v-if="form.show_welcome_page === true">
									<div class="flex flex-col px-5 py-2 text-gray-700">
										<label class="text-xs text-left">Welcome Page Title</label>
										<div class="mt-2 relative rounded-md shadow-sm">
											<input v-model="form.attributes.welcome_page.title" :disabled="!getIsActiveFormEditable" type="text" class="form-input w-full px-3 py-2 appearance-none rounded-md focus:border-indigo-600 disabled:opacity-60"/>
										</div>
									</div>
									<div class="flex flex-col px-5 py-2 text-gray-700">
										<label class="text-xs text-left">Welcome Page Image URL</label>
										<div class="mt-2 relative rounded-md shadow-sm">
											<input v-model="form.attributes.welcome_page.image_url" :disabled="!getIsActiveFormEditable" type="text" class="form-input w-full px-3 py-2 appearance-none rounded-md focus:border-indigo-600 disabled:opacity-60"/>
										</div>
									</div>
									<div class="flex flex-col px-5 py-2 text-gray-700">
										<label class="text-xs text-left">Welcome Page Description</label>
										<div class="mt-2 relative rounded-md shadow-sm">
											<input v-model="form.attributes.welcome_page.description" :disabled="!getIsActiveFormEditable" type="text" class="form-input w-full px-3 py-2 appearance-none rounded-md focus:border-indigo-600 disabled:opacity-60"/>
										</div>
									</div>
									<div class="flex flex-col px-5 py-2 text-gray-700">
										<label class="text-xs text-left">Welcome Page Button Text</label>
										<div class="mt-2 relative rounded-md shadow-sm">
											<input v-model="form.attributes.welcome_page.button_text" :disabled="!getIsActiveFormEditable" type="text" class="form-input w-full px-3 py-2 appearance-none rounded-md focus:border-indigo-600 disabled:opacity-60"/>
										</div>
									</div>
								</div>
							</div>
							<div class="flex flex-col px-5 pb-6 text-gray-700">
								<label class="text-xs text-left">Show Summary Page</label>
								<div class="flex items-center w-full mb-2 mt-4">
									<label class="flex items-center cursor-pointer">
										<div class="relative">
											<input v-model="form.show_summary_page" :disabled="!getIsActiveFormEditable" type="checkbox" class="hidden" />
											<div class="toggle__line w-10 h-4 bg-gray-400 rounded-full shadow-inner"></div>
											<div class="toggle__dot absolute w-6 h-6 bg-white rounded-full shadow inset-y-0 left-0"></div>
										</div>
									</label>
								</div>
							</div>
							<div class="flex flex-col px-5 pb-6 text-gray-700">
								<label class="text-xs text-left">Show Thank You Page</label>
								<div class="flex items-center w-full mb-2 mt-4">
									<label class="flex items-center cursor-pointer">
										<div class="relative">
											<input v-model="form.show_thankyou_page" :disabled="!getIsActiveFormEditable" type="checkbox" class="hidden" />
											<div class="toggle__line w-10 h-4 bg-gray-400 rounded-full shadow-inner"></div>
											<div class="toggle__dot absolute w-6 h-6 bg-white rounded-full shadow inset-y-0 left-0"></div>
										</div>
									</label>
								</div>
								<div v-if="form.show_thankyou_page === true">
									<div class="flex flex-col px-5 py-2 text-gray-700">
										<label class="text-xs text-left">Thank You Page Title</label>
										<div class="mt-2 relative rounded-md shadow-sm">
											<input v-model="form.attributes.thankyou_page.title" :disabled="!getIsActiveFormEditable" type="text" class="form-input w-full px-3 py-2 appearance-none rounded-md focus:border-indigo-600 disabled:opacity-60"/>
										</div>
									</div>
									<div class="flex flex-col px-5 py-2 text-gray-700">
										<label class="text-xs text-left">Thank You Page Image URL</label>
										<div class="mt-2 relative rounded-md shadow-sm">
											<input v-model="form.attributes.thankyou_page.image_url" :disabled="!getIsActiveFormEditable" type="text" class="form-input w-full px-3 py-2 appearance-none rounded-md focus:border-indigo-600 disabled:opacity-60"/>
										</div>
									</div>
									<div class="flex flex-col px-5 py-2 text-gray-700">
										<label class="text-xs text-left">Thank You Page Description</label>
										<div class="mt-2 relative rounded-md shadow-sm">
											<input v-model="form.attributes.thankyou_page.description" :disabled="!getIsActiveFormEditable" type="text" class="form-input w-full px-3 py-2 appearance-none rounded-md focus:border-indigo-600 disabled:opacity-60"/>
										</div>
									</div>
									<div class="flex flex-col px-5 py-2 text-gray-700">
										<label class="text-xs text-left">Thank You Page Button Text</label>
										<div class="mt-2 relative rounded-md shadow-sm">
											<input v-model="form.attributes.thankyou_page.button_text" :disabled="!getIsActiveFormEditable" type="text" class="form-input w-full px-3 py-2 appearance-none rounded-md focus:border-indigo-600 disabled:opacity-60"/>
										</div>
									</div>
								</div>
							</div>
							<div class="flex justify-end items-center px-5 py-3">
								<button type="submit"
								:disabled="!getIsActiveFormEditable"
								class="px-3 py-1 text-white rounded-md text-sm focus:outline-none bg-red-600 hover:bg-red-500 text-base disabled:bg-red-200">
									Save
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
  </div>
</template>
<script>
import { mapMutations, mapGetters, mapActions } from 'vuex'
import { defineComponent } from 'vue';
export default defineComponent ({
	name: 'FormSettings',
	props: {},
	data: () => {
		return {
			form: {}
		}
	},
	components: {},
	computed: {
		...mapGetters([
			'getActiveForm',
			'getActiveFormOriginal',
			'getIsActiveFormEditable'
		])
	},
	watch: {
	},
	created () {
		this.form = JSON.parse(JSON.stringify(this.getActiveForm.settings))
		// this.form = this.getActiveForm.settings
		if (!this.form.attributes) {
			this.form.attributes = {
				'welcome_page': {
					'title': '',
					'image_url': '',
					'description': '',
					'button_text': ''
				},
				'thankyou_page': {
					'title': '',
					'image_url': '',
					'description': '',
					'button_text': ''
				}
			}
		}
	},
	mounted () {
	},
	methods: {
		...mapMutations([
			'setActiveFormId',
			'setShowSnackbar',
			'setShowSuccessSnackbar'
		]),
		...mapActions([
			'saveFormSettings'
		]),
		compileForm: function () {
			let data = {
				'is_public': this.form.is_public,
				// 'progress': this.form.progress,
				// 'rendering_type': this.form.rendering_type,
				'submissions_per_user': +this.form.submissions_per_user,
				'show_welcome_page': this.form.show_welcome_page,
				'show_summary_page': this.form.show_summary_page,
				'show_thankyou_page': this.form.show_thankyou_page,
				'attributes': {
					'welcome_page': {
						'title': this.form.attributes.welcome_page.title,
						'image_url': this.form.attributes.welcome_page.image_url,
						'description': this.form.attributes.welcome_page.description,
						'button_text': this.form.attributes.welcome_page.button_text
					},
					'thankyou_page': {
						'title': this.form.attributes.thankyou_page.title,
						'image_url': this.form.attributes.thankyou_page.image_url,
						'description': this.form.attributes.thankyou_page.description,
						'button_text': this.form.attributes.thankyou_page.button_text
					}
				}
			}
			this.saveFormSettings(data).then((res) => {
				if (res.status === 'error') {
					this.setShowSnackbar(res.msg)
					// reset form?
					this.form = this.getActiveForm.settings
				} else {
					this.setShowSuccessSnackbar(res.status)
				}
			})
		}
	}
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  /* color: #42b983; */
}
.toggle__dot {
  top: -.25rem;
  left: -.25rem;
  transition: all 0.3s ease-in-out;
}

input:checked ~ .toggle__dot {
  transform: translateX(100%);
  background-color: #dc2626;
}
</style>
