<template>
	<div>
		<div class="flex flex-col  pb-6 text-gray-700">
			<div class="mt-2 relative rounded-md shadow-sm">
				<div class="relative flex flex-col mt-2">
					<label class="text-xs text-left">Image</label>
					<input
						type="text"
						v-model="img"
						:disabled="!getIsActiveFormEditable"
						class="mt-2 form-input w-full px-3 py-2 appearance-none rounded-md focus:border-indigo-600 disabled:opacity-60"
					/>
				</div>
				<div class="relative flex flex-col mt-2">
					<label class="text-xs text-left">Image Width</label>
					<input
						type="text"
						v-model="width"
						:disabled="!getIsActiveFormEditable"
						class="mt-2 form-input w-full px-3 py-2 appearance-none rounded-md focus:border-indigo-600 disabled:opacity-60"
					/>
				</div>
				<div class="relative flex flex-col mt-2">
					<label class="text-xs text-left">Image Height</label>
					<input
						type="text"
						v-model="height"
						:disabled="!getIsActiveFormEditable"
						class="mt-2 form-input w-full px-3 py-2 appearance-none rounded-md focus:border-indigo-600 disabled:opacity-60"
					/>
				</div>
				<div class="relative flex flex-col mt-2">
					<label class="text-xs text-left">Choices</label>
					<textarea v-model="choices" :disabled="!getIsActiveFormEditable" rows="3" class="mt-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border-gray-500 rounded-md disabled:opacity-60" placeholder="comma separated list"></textarea>
				</div>
				<div class="relative flex flex-col mt-2">
					<label class="text-xs text-left">Multi-Select</label>
					<div class="flex items-center w-full mb-2 mt-4">
						<label v-if="currentlyEditing" key="editShowimage" :for="`showImage${currentlyEditing.id}`" class="flex items-center cursor-pointer">
							<div class="relative">
								<input v-model="multi" :id="`showImage${currentlyEditing.id}`" :disabled="!getIsActiveFormEditable" type="checkbox" class="hidden" />
								<div class="toggle__line w-10 h-4 bg-gray-400 rounded-full shadow-inner"></div>
								<div class="toggle__dot absolute w-6 h-6 bg-white rounded-full shadow inset-y-0 left-0"></div>
							</div>
						</label>
						<label v-else key="mewShowimage" for="toogleMultiShowImage" class="flex items-center cursor-pointer">
							<div class="relative">
								<input v-model="multi" id="toogleMultiShowImage" :disabled="!getIsActiveFormEditable" type="checkbox" class="hidden" />
								<div class="toggle__line w-10 h-4 bg-gray-400 rounded-full shadow-inner"></div>
								<div class="toggle__dot absolute w-6 h-6 bg-white rounded-full shadow inset-y-0 left-0"></div>
							</div>
						</label>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { defineComponent } from 'vue';

export default defineComponent ({
	name: 'OptionFieldShowImage',
	props: {
		clear: {
			type: Boolean
		},
		currentlyEditing: {
			type: Object
		},
		addNew: {
			type: Boolean
		},
		index: {
			type: Number
		}
	},
	data: () => {
		return {
			img: '',
			multi: false,
			choices: '',
			width: '',
			height: ''
		}
	},
	components: {},
	computed: {
		...mapGetters([
			'getActiveQuestionId',
			'getIsEdit',
			'getIsActiveFormEditable'
		])
	},
	watch: {
		img(value) {
			this.updateImg(value)
		},
		clear(value) {
			if (value === true) {
				this.img = ''
			}
		},
		width(value) {
			this.updateWidth(value)
		},
		height(value) {
			this.updateHeight(value)
		},
		choices(value) {
			this.updateChoices(value.split(','))
		},
		multi(value) {
			this.updateMulti(value)
		}
	},
	created () {
		this.$emit('validate', false)
		if (this.getIsEdit) {
			if (!this.addNew && this.currentlyEditing) {
				this.img = this.currentlyEditing.image_url
				this.width = this.currentlyEditing.config.width
				this.height = this.currentlyEditing.config.height
				this.multi = this.currentlyEditing.config.multi_select
				this.choices = this.currentlyEditing.choices.join(',')
				this.$emit('validate', true)
			}
		}
	},
	mounted () {},
	methods: {
		...mapMutations([
			'setModalContent',
			'setShowModal',
			'closeModal',
			'setWorkingOption',
			'modifyActiveOption'
		]),
		...mapActions([
		]),
		updateImg: function (value) {
			let data = {
				// 'ref': '111222',
				'question_id': this.getActiveQuestionId,
				'type': 'show_image',
				'order': 1,
				'image_url': value,
				'choices': this.choices.split(','),
				'config': {
					'width': this.width,
					'height': this.height,
					'multi_select':  this.multi
				}
			}
			this.setWorkingOption(data)
			this.$emit('validate', true)
			if (this.getIsEdit && this.currentlyEditing) {
				data.id = this.currentlyEditing.id
				data.check = true
				let info = {
					'index': this.index,
					'modified': data
				}
				this.modifyActiveOption(info)
			}
		},
		updateChoices: function (value) {
			let data = {
				// 'ref': '111222',
				'question_id': this.getActiveQuestionId,
				'type': 'show_image',
				'order': 1,
				'image_url': this.img,
				'choices': value,
				'config': {
					'width': this.width,
					'height': this.height,
					'multi_select':  this.multi
				}
			}
			this.setWorkingOption(data)
			this.$emit('validate', true)
			if (this.getIsEdit && this.currentlyEditing) {
				data.id = this.currentlyEditing.id
				data.check = true
				let info = {
					'index': this.index,
					'modified': data
				}
				this.modifyActiveOption(info)
			}
		},
		updateWidth: function (value) {
			let data = {
				// 'ref': '111222',
				'question_id': this.getActiveQuestionId,
				'type': 'show_image',
				'order': 1,
				'image_url': this.img,
				'choices': this.choices.split(','),
				'config': {
					'width': value,
					'height': this.height,
					'multi_select':  this.multi
				}
			}
			this.setWorkingOption(data)
			this.$emit('validate', true)
			if (this.getIsEdit && this.currentlyEditing) {
				data.id = this.currentlyEditing.id
				data.check = true
				let info = {
					'index': this.index,
					'modified': data
				}
				this.modifyActiveOption(info)
			}
		},
		updateHeight: function (value) {
			let data = {
				// 'ref': '111222',
				'question_id': this.getActiveQuestionId,
				'type': 'show_image',
				'order': 1,
				'image_url': this.img,
				'choices': this.choices.split(','),
				'config': {
					'width': this.width,
					'height': value,
					'multi_select':  this.multi
				}
			}
			this.setWorkingOption(data)
			this.$emit('validate', true)
			if (this.getIsEdit && this.currentlyEditing) {
				data.id = this.currentlyEditing.id
				data.check = true
				let info = {
					'index': this.index,
					'modified': data
				}
				this.modifyActiveOption(info)
			}
		},
		updateMulti: function (value) {
			let data = {
				// 'ref': '111222',
				'question_id': this.getActiveQuestionId,
				'type': 'show_image',
				'order': 1,
				'image_url': this.img,
				'choices': this.choices.split(','),
				'config': {
					'width': this.width,
					'height': this.height,
					'multi_select': value
				}
			}
			this.setWorkingOption(data)
			this.$emit('validate', true)
			if (this.getIsEdit && this.currentlyEditing) {
				data.id = this.currentlyEditing.id
				data.check = true
				let info = {
					'index': this.index,
					'modified': data
				}
				this.modifyActiveOption(info)
			}
		}
	}
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.toggle__dot {
  top: -.25rem;
  left: -.25rem;
  transition: all 0.3s ease-in-out;
}

input:checked ~ .toggle__dot {
  transform: translateX(100%);
  background-color: #dc2626;
}
</style>
