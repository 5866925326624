<template>
	<div>
		<div
			class="flex justify-between items-center px-5 py-3 text-gray-700 border-b"
		>
			<!-- <h3 class="text-sm">{{$route.name}}</h3> -->
			<div class="flex justify-between items-center px-5">
				<div v-if="getActiveOptions" class="mr-4" >
					<button
						@click="goToOptions()"
						:disabled="validation"
						:class="computedClassOptionsButton"
						class="px-3 py-1 text-white text-sm rounded-md  focus:outline-none flex justify-center items-center">
						<svg v-if="view === 'question'" class="w-6 h-6 inline-block mr-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="#ffffff">
							<path fill="none" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18"/>
						</svg>
						<p class="text-base">Options</p>
					</button>
				</div>
				<button
					@click="goToQuestion()"
					:disabled="validation"
					class="px-3 py-1 text-white text-sm rounded-md  focus:outline-none flex justify-center items-center"
					:class="computedClassQuestionButton"
				>
					<p class="text-base">Question</p>
					<svg v-if="view === 'options'" class="w-6 h-6 inline-block ml-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="#ffffff">
						<path fill="none" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"/>
					</svg>
				</button>
			</div>
			<button @click="closeModal">
				<svg
					class="h-4 w-4"
					fill="none"
					viewBox="0 0 24 24"
					stroke="currentColor"
				>
					<path
						stroke-linecap="round"
						stroke-linejoin="round"
						stroke-width="2"
						d="M6 18L18 6M6 6l12 12"
					/>
				</svg>
			</button>
		</div>
		<div v-if="view === 'question'" key="viewQuestion">
			<div class="flex flex-col px-5 py-6 bg-gray-200 text-gray-700 border-b">
				<label class="text-xs text-left">Question</label>
				<div class="mt-2 relative rounded-md shadow-sm">
					<input
						:disabled="!getIsActiveFormEditable"
						type="text"
						v-model="title"
						class="form-input w-full px-3 py-2 appearance-none rounded-md focus:border-indigo-600"
					/>
				</div>
			</div>
			<div class="flex flex-col px-5 pb-6 bg-gray-200 text-gray-700 border-b">
				<label class="text-xs text-left">Description</label>
				<div class="mt-2 relative rounded-md shadow-sm">
					<input
						type="text"
						v-model="description"
						:disabled="!getIsActiveFormEditable"
						class="form-input w-full px-3 py-2 appearance-none rounded-md focus:border-indigo-600"
					/>
				</div>
			</div>
			<div class="flex flex-col px-5 pb-6 bg-gray-200 text-gray-700 border-b">
				<label class="text-xs text-left">Help Text</label>
				<div class="mt-2 relative rounded-md shadow-sm">
					<input
						type="text"
						v-model="help_text"
						:disabled="!getIsActiveFormEditable"
						class="form-input w-full px-3 py-2 appearance-none rounded-md focus:border-indigo-600"
					/>
				</div>
			</div>
			<div class="flex flex-col px-5 pb-6 bg-gray-200 text-gray-700 border-b">
				<label class="text-xs text-left">Required</label>
				<div class="flex items-center w-full mb-2 mt-4">
					<label for="toogleA" class="flex items-center cursor-pointer">
						<div class="relative">
							<input :disabled="!getIsActiveFormEditable" v-model="is_required" id="toogleA" type="checkbox" class="hidden" />
							<div class="toggle__line w-10 h-4 bg-gray-400 rounded-full shadow-inner"></div>
							<div class="toggle__dot absolute w-6 h-6 bg-white rounded-full shadow inset-y-0 left-0"></div>
						</div>
					</label>
				</div>
				<!-- :disabled="validation || !changed || !getIsActiveFormEditable" -->
				<button  @click="saveQuestion()"
				:class="validation || !changed ? 'bg-red-200': 'bg-red-600 hover:bg-red-500'"
					class="px-3 py-3 mt-5 text-white rounded-md text-sm  focus:outline-none disabled:bg-red-200"
				>
					Save Changes
				</button>
			</div>
		</div>
		<ModalQuestionEditOptions v-if="view === 'options'"/>
		<div class="py-3"></div>
		<!-- <div class="flex justify-between items-center px-5 py-3">
			<div v-if="getActiveOptions" >
				<button
					@click="goToOptions()"
					:disabled="validation"
					:class="computedClassOptionsButton"
					class="px-3 py-1 text-white text-sm rounded-md  focus:outline-none flex justify-center items-center">
					<svg v-if="view === 'question'" class="w-6 h-6 inline-block mr-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="#ffffff">
						<path fill="none" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18"/>
					</svg>
					<p class="text-base">Options</p>
				</button>
			</div>
			<button
				@click="goToQuestion()"
				:disabled="validation"
				class="px-3 py-1 text-white text-sm rounded-md  focus:outline-none flex justify-center items-center"
				:class="computedClassQuestionButton"
			>
				<p class="text-base">Question</p>
				<svg v-if="view === 'options'" class="w-6 h-6 inline-block ml-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="#ffffff">
					<path fill="none" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"/>
				</svg>
			</button>
		</div> -->
	</div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { defineComponent } from 'vue';
import ModalQuestionEditOptions from './ModalQuestionEditOptions.vue'

export default defineComponent ({
	name: 'ModalQuestionEdit',
	props: {},
	data: () => {
		return {
			view: 'question',
			title: '',
			help_text: '',
			description: '',
			is_required: false,
			order: 0,
			status: 'show',
			changed: false
		}
	},
	components: {
		ModalQuestionEditOptions
	},
	computed: {
		...mapGetters([
			'getQuestions',
			'getActiveQuestion',
			'getActiveOptions',
			'getIsEdit',
			'getModifyingOriginalOptions',
			'getIsActiveFormEditable'
		]),
		validation() {
			if (this.title === '') {
				return true
			} else {
				return false
			}
		},
		computedClassOptionsButton() {
			let className = ''
			if (this.view === 'options') {
				if (this.validation) {
					className = 'border border-red-200 text-red-200'
				} else {
					className = 'border border-red-600 text-red-600'
				}
			} else {
				if (this.validation) {
					className = 'bg-red-200'
				} else {
					className = 'bg-red-600 hover:bg-red-500'
				}
			}
			return className
		},
		computedClassQuestionButton() {
			let className = ''
			if (this.view === 'question') {
				if (this.validation) {
					className = 'border border-red-200 text-red-200'
				} else {
					className = 'border border-red-600 text-red-600'
				}
			} else {
				if (this.validation) {
					className = 'bg-red-200'
				} else {
					className = 'bg-red-600 hover:bg-red-500'
				}
			}
			return className
		}
	},
	watch: {
		title(newValue) {
			if (newValue !== this.getActiveQuestion.title) {
				this.changed = true
			}
		},
		help_text(newValue) {
			if (newValue !== this.getActiveQuestion.help_text) {
				this.changed = true
			}
		},
		description(newValue) {
			if (newValue !== this.getActiveQuestion.description) {
				this.changed = true
			}
		},
		is_required(newValue) {
			if (newValue !== this.getActiveQuestion.is_required) {
				this.changed = true
			}
		}
	},
	created () {
		this.title = this.getActiveQuestion.title
		this.help_text = this.getActiveQuestion.help_text
		this.description = this.getActiveQuestion.description
		this.is_required = this.getActiveQuestion.is_required
		this.order = this.getActiveQuestion.order
		// this.status = this.getActiveQuestion.status
		this.listQuestionOptions().then((res) => {
			this.setActiveOptions(res.payload)
			this.setModifyingOriginalOptions({})
			if (this.getIsEdit) {
				let originalOptionsArray = []
				for (let x = 0; x < this.getActiveOptions.length; x++) {
					originalOptionsArray.push({})
					if (x === this.getActiveOptions.length - 1) {
						this.setModifyingOriginalOptions(originalOptionsArray)
					}
				}
			}
		})
	},
	mounted () {},
	methods: {
		...mapMutations([
			'setModalContent',
			'setShowModal',
			'closeModal',
			'setActiveOptions',
			'setModifyingOriginalOptions',
			'setShowSnackbar',
			'setShowSuccessSnackbar'
		]),
		...mapActions([
			'createQuestion',
			'listQuestionOptions',
			'getActiveSectionQuestions',
			'updateQuestion'
		]),
		saveQuestion: function () {
			let question = {
				'title': this.title,
				'help_text': this.help_text,
				'description': this.description,
				'is_required': this.is_required,
				'order': this.getActiveQuestion.order
				// 'status': this.status
			}
			this.updateQuestion(question).then((res) => {
				this.getActiveSectionQuestions()
				if (res.status === 'error') {
					this.setShowSnackbar(res.msg)
					console.log('error')
				} else {
					// this.setModalContent('') ?
					// this.setShowModal(false) ?
					this.setShowSuccessSnackbar(res.status)
					this.changed = false
				}
			})
		},
		goToOptions: function () {
			this.view = 'options'
		},
		goToQuestion: function () {
			this.view = 'question'
		}
	}
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.toggle__dot {
  top: -.25rem;
  left: -.25rem;
  transition: all 0.3s ease-in-out;
}

input:checked ~ .toggle__dot {
  transform: translateX(100%);
  background-color: #dc2626;
}
</style>
