
import { defineComponent } from 'vue';
import { mapMutations } from 'vuex'

export default defineComponent({
  name: 'Submissions',
  props: {
    msg: String,
  },
	created () {
		this.setActiveSectionId('')
		this.setQuestions(false)
		this.setActiveFormId('')
		this.setActiveForm({})
		this.setSections(false)
	},
	methods: {
		...mapMutations([
			'setActiveSectionId',
			'setQuestions',
			'setActiveFormId',
			'setActiveForm',
			'setSections'
		]),
	}
 });
