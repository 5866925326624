<template>
	<div>
		<div
			class="flex justify-between items-center px-5 py-3 text-gray-700 border-b"
		>
			<h3 class="text-sm">{{$route.name}}</h3>
			<button @click="closeModal">
				<svg
					class="h-4 w-4"
					fill="none"
					viewBox="0 0 24 24"
					stroke="currentColor"
				>
					<path
						stroke-linecap="round"
						stroke-linejoin="round"
						stroke-width="2"
						d="M6 18L18 6M6 6l12 12"
					/>
				</svg>
			</button>
		</div>
		<div class="flex flex-col px-5 py-6 bg-gray-200 text-gray-700 border-b">
			<label class="text-xs text-left">Question</label>
			<div class="mt-2 relative rounded-md shadow-sm">
				<input
					type="text"
					v-model="title"
					class="form-input w-full px-3 py-2 appearance-none rounded-md focus:border-indigo-600"
				/>
			</div>
		</div>
		<div class="flex flex-col px-5 pb-6 bg-gray-200 text-gray-700 border-b">
			<label class="text-xs text-left">Description</label>
			<div class="mt-2 relative rounded-md shadow-sm">
				<input
					type="text"
					v-model="description"
					class="form-input w-full px-3 py-2 appearance-none rounded-md focus:border-indigo-600"
				/>
			</div>
		</div>
		<div class="flex flex-col px-5 pb-6 bg-gray-200 text-gray-700 border-b">
			<label class="text-xs text-left">Help Text</label>
			<div class="mt-2 relative rounded-md shadow-sm">
				<input
					type="text"
					v-model="help_text"
					class="form-input w-full px-3 py-2 appearance-none rounded-md focus:border-indigo-600"
				/>
			</div>
		</div>
		<div class="flex flex-col px-5 pb-6 bg-gray-200 text-gray-700 border-b">
			<label class="text-xs text-left">Status</label>
			<div class="mt-2 relative rounded-md shadow-sm">
				<div class="relative">
					<select
					v-model="status"
						class="appearance-none h-full rounded border block appearance-none w-full bg-white border-gray-500 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
					>
					<option v-for="(option, index) in options" :key="`statusoptions${index}`">{{option}}</option>
					</select>
				</div>
			</div>
		</div>
		<div class="flex flex-col px-5 pb-6 bg-gray-200 text-gray-700 border-b">
			<label class="text-xs text-left">Required</label>
			<div class="flex items-center w-full mb-2 mt-4">
				<label for="toogleA" class="flex items-center cursor-pointer">
					<div class="relative">
						<input v-model="is_required" id="toogleA" type="checkbox" class="hidden" />
						<div class="toggle__line w-10 h-4 bg-gray-400 rounded-full shadow-inner"></div>
						<div class="toggle__dot absolute w-6 h-6 bg-white rounded-full shadow inset-y-0 left-0"></div>
					</div>
				</label>
			</div>
		</div>
		<div class="flex justify-between items-center px-5 py-3">
			<button @click="createNewQuestion"
			:disabled="validation"
				class="px-3 py-1 text-white text-sm rounded-md  focus:outline-none"
				:class="validation ? 'bg-red-200': 'bg-red-600 hover:bg-red-500'"
			>
				Create
			</button>
			<button :disabled="validation" @click="createNewQuestion('questionOptions')"
				class="px-3 py-1 text-white rounded-md text-sm  focus:outline-none"
				:class="validation ? 'bg-red-200': 'bg-red-600 hover:bg-red-500'"
			>
				Add Options
			</button>
		</div>
	</div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { defineComponent } from 'vue';

export default defineComponent ({
	name: 'ModalQuestionNew',
	props: {},
	data: () => {
		return {
			title: '',
			help_text: '',
			description: '',
			is_required: false,
			order: 0,
			status: 'show',
			options: [
				'show',
				'hidden',
				'disabled',
				'enabled'
			]
		}
	},
	components: {},
	computed: {
		...mapGetters([
			'getQuestions'
		]),
		validation() {
			if (this.title === '') {
				return true
			} else {
				return false
			}
		}
	},
	watch: {},
	created () {},
	mounted () {},
	methods: {
		...mapMutations([
			'setModalContent',
			'setShowModal',
			'closeModal',
			'setShowSnackbar',
			'setActiveQuestionId',
			'setShowSuccessSnackbar'
		]),
		...mapActions([
			'createQuestion',
			'getActiveSectionQuestions'
		]),
		createNewQuestion: function (modalContent) {
			let question = {
				'title': this.title,
				'help_text': this.help_text,
				'description': this.description,
				'is_required': this.is_required,
				'order': this.getQuestions.length + 1,
				'status': this.status
			}
			this.createQuestion(question).then((res) => {
				if (res.status === 'error') {
					this.setShowSnackbar(res.msg)
				} else {
					this.getActiveSectionQuestions()
					this.setActiveQuestionId(res)
					this.setShowSuccessSnackbar(res.status)
					if (modalContent === 'questionOptions') {
						this.setModalContent('questionOptions')
					} else {
						this.setModalContent('')
						this.setShowModal(false)
					}
				}
			})
		}
	}
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.toggle__dot {
  top: -.25rem;
  left: -.25rem;
  transition: all 0.3s ease-in-out;
}

input:checked ~ .toggle__dot {
  transform: translateX(100%);
  background-color: #dc2626;
}
</style>
