<template>
	<div>
		<div
			class="flex justify-between items-center px-5 py-3 text-gray-700 border-b"
		>
			<h3 class="text-sm">Create a Section </h3>
			<button  @click="closeModal">
				<svg
					class="h-4 w-4"
					fill="none"
					viewBox="0 0 24 24"
					stroke="currentColor"
				>
					<path
						stroke-linecap="round"
						stroke-linejoin="round"
						stroke-width="2"
						d="M6 18L18 6M6 6l12 12"
					/>
				</svg>
			</button>
		</div>
		<div class="flex flex-col justify-between items-center px-5 py-6 bg-gray-200 text-gray-700 border-b">
			<button
				@click="setModalContent('selectSectionLayoutConfig-table')"
				class="px-3 py-1 text-white text-sm rounded-md bg-red-600 hover:bg-red-500 focus:outline-none"
			>
				Table Layout
			</button>
			<button
				@click="setModalContent('selectSectionLayoutConfig-grid')"
				class="mt-3 px-3 py-1 bg-red-600 text-white rounded-md text-sm hover:bg-red-500 focus:outline-none"
			>
				Grid Layout
			</button>
		</div>
	</div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import { defineComponent } from 'vue';

export default defineComponent ({
	name: 'ModalSelectSectionLayout',
	props: {},
	data: () => {
		return {}
	},
	components: {
		// VueTailwindPicker
	},
	computed: {
		...mapGetters([
		])
	},
	watch: {},
	created () {},
	mounted () {},
	methods: {
		...mapMutations([
			'setModalContent',
			'closeModal'
		])
	}
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
