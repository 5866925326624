<template>
	<div>
		<div class="flex flex-col  pb-6 text-gray-700">
			<div class="mt-2 relative rounded-md shadow-sm">
				<div class="relative flex flex-col mt-2">
					<label class="text-xs text-left">Default Value</label>
					<input
						type="text"
						v-model="default_value"
						:disabled="!getIsActiveFormEditable"
						class="mt-2 form-input w-full px-3 py-2 appearance-none rounded-md focus:border-indigo-600 disabled:opacity-60"
					/>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { defineComponent } from 'vue';

export default defineComponent ({
	name: 'OptionFieldCalculatedField',
	props: {
		clear: {
			type: Boolean
		},
		currentlyEditing: {
			type: Object
		},
		addNew: {
			type: Boolean
		},
		index: {
			type: Number
		}
	},
	data: () => {
		return {
			default_value: ''
		}
	},
	components: {},
	computed: {
		...mapGetters([
			'getActiveQuestionId',
			'getIsEdit',
			'getIsActiveFormEditable'
		])
	},
	watch: {
		default_value(value) {
			this.updateDefaultValue(value)
		},
		clear(value) {
			if (value === true) {
				this.default_value = ''
			}
		}
	},
	created () {
		this.$emit('validate', false)
		if (this.getIsEdit) {
			if (!this.addNew && this.currentlyEditing) {
				this.default_value = this.currentlyEditing.default_value
				this.$emit('validate', true)
			}
		}
	},
	mounted () {},
	methods: {
		...mapMutations([
			'setModalContent',
			'setShowModal',
			'closeModal',
			'setWorkingOption',
			'modifyActiveOption'
		]),
		...mapActions([
		]),
		updateDefaultValue: function (value) {
			let data = {
				'question_id': this.getActiveQuestionId,
				'type': 'calculated',
				'order': 1,
				'default_value': value
			}
			this.setWorkingOption(data)
			this.$emit('validate', true)
			if (this.getIsEdit && this.currentlyEditing) {
				data.id = this.currentlyEditing.id
				data.check = true
				let info = {
					'index': this.index,
					'modified': data
				}
				this.modifyActiveOption(info)
			}
		}
	}
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.toggle__dot {
  top: -.25rem;
  left: -.25rem;
  transition: all 0.3s ease-in-out;
}

input:checked ~ .toggle__dot {
  transform: translateX(100%);
  background-color: #dc2626;
}
</style>
