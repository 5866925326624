<template>
  <div class="flex">
		<aside
			class="flex flex-col items-center bg-white text-gray-700 shadow h-full transition-width duration-200 ease-in"
			:class="getMainSidebarOpen ? 'w-48' : 'w-20'">
			<div class="h-16 flex items-center w-full">
				<button @click="setSidebarOpen(!getMainSidebarOpen)" class="px-6 text-gray-500 focus:outline-none mr-8">
					<svg class="h-6 w-6" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M4 6H20M4 12H20M4 18H11" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
					</svg>
				</button>
			</div>
			<SidebarMain v-if="$route.meta.showSidebarMain && !$route.meta.showSidebarSections" />
			<SidebarSections v-if="$route.meta.showSidebarSections" />
		</aside>
  </div>
</template>

<script>
import SidebarMain from './SidebarMain.vue'
import SidebarSections from './SidebarSections.vue'
import { mapGetters, mapMutations } from 'vuex'
export default {
	name: 'Sidebar',
	props: {},
	data() {
		return {}
	},
	components: {
		SidebarMain,
		SidebarSections
	},
	computed: {
		...mapGetters([
			'getMainSidebarOpen'
		])
	},
	watch: {},
	created () {},
	mounted () {},
	methods: {
		...mapMutations([
			'setSidebarOpen',
		])
	}
};
</script>
