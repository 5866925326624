<template>
	<div class="min-h-screen max-h-screen bg-gray-700 py-6 flex flex-col justify-center sm:py-12 fixed bg-opacity-80 w-full z-40">
		<div
			class="container py-3 sm:max-w-xl mx-auto max-w-sm w-full bg-white shadow-lg rounded-xl overflow-scroll"
		>
		<ModalSelectSectionLayout v-if="getModalContent === 'selectSectionLayout'"/>
		<ModalSectionLayoutConfig v-if="getModalContent === 'selectSectionLayoutConfig-table' || getModalContent === 'selectSectionLayoutConfig-grid'"/>
		<ModalQuestionNew v-if="getModalContent === 'questionNew'"/>
		<ModalQuestionEdit v-if="getModalContent === 'questionEdit'"/>
		<ModalQuestionNewAddOptions v-if="getModalContent ==='questionOptions'" />
		<ModalSectionEdit v-if="getModalContent ==='sectionEdit'" />
		<ModalDelete v-if="getModalContent ==='deleteQuestion' || getModalContent ==='deleteSection'"/>
	</div>
	</div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import { defineComponent } from 'vue';
import ModalSelectSectionLayout from './ModalSelectSectionLayout.vue'
import ModalSectionLayoutConfig from './ModalSectionLayoutConfig.vue'
import ModalQuestionNew from './ModalQuestionNew.vue'
import ModalQuestionEdit from './ModalQuestionEdit.vue'
import ModalQuestionNewAddOptions from './ModalQuestionNewAddOptions.vue'
import ModalSectionEdit from './ModalSectionEdit.vue'
import ModalDelete from './ModalDelete.vue'

export default defineComponent ({
	name: 'Modal',
	props: {},
	data: () => {
		return {}
	},
	components: {
		ModalSelectSectionLayout,
		ModalSectionLayoutConfig,
		ModalQuestionNew,
		ModalQuestionNewAddOptions,
		ModalQuestionEdit,
		ModalSectionEdit,
		ModalDelete
	},
	computed: {
		...mapGetters([
			'getModalContent'
		])
	},
	watch: {},
	created () {},
	mounted () {},
	methods: {
		...mapMutations([])
	}
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
