<template>
	<div>
		<div
			class="flex justify-between items-center px-5 py-3 text-gray-700 border-b"
		>
			<h3 class="text-sm capitalize">delete selected {{item}}?</h3>
			<button @click="cancel">
				<svg
					class="h-4 w-4"
					fill="none"
					viewBox="0 0 24 24"
					stroke="currentColor"
				>
					<path
						stroke-linecap="round"
						stroke-linejoin="round"
						stroke-width="2"
						d="M6 18L18 6M6 6l12 12"
					/>
				</svg>
			</button>
		</div>
		<div>
			<div class="flex flex-col px-5 py-6 bg-gray-200 text-gray-700 border-b">
				<!-- <label class="text-xs text-left">Question</label> -->
			{{message}}
			</div>
		</div>
		<div class="flex justify-between items-center px-5 py-3">
			<button
				@click="cancel"
				class="px-3 py-1 border border-red-600 text-red-600 hover:bg-red-100 text-sm rounded-md flex justify-center items-center">
				<p class="text-base">Cancel</p>
			</button>
			<button
				@click="confirmDelete"
				class="px-3 py-1 bg-red-600 text-white text-sm rounded-md flex justify-center items-center"
			>
				<p class="text-base">Delete</p>
				<svg class="w-6 h-6 inline-block ml-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="#ffffff">
					<path fill="none" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"/>
				</svg>
			</button>
		</div>
	</div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { defineComponent } from 'vue';

export default defineComponent ({
	name: 'ModalDelete',
	props: {},
	data: () => {
		return {
			item: '',
			message: ''
		}
	},
	components: {},
	computed: {
		...mapGetters([
			'getModalContent',
			'getModifyingSection',
			'getActiveQuestion'
		])
	},
	watch: {},
	created () {
		if (this.getModalContent === 'deleteQuestion') {
			this.item = 'question'
			this.message = 'This will delete all the options associated with the question.  Proceed?'
		} else if (this.getModalContent === 'deleteSection') {
			this.item = 'section'
			this.message = 'This will delete all the questions associated with the section.  Proceed?'
		}
	},
	mounted () {},
	methods: {
		...mapMutations([
			'setModalContent',
			'setShowModal',
			'setActiveSectionId',
			'setSections'
		]),
		...mapActions([
			'removeQuestion',
			'removeSection',
			'getFormSections'
		]),
		cancel: function () {
			this.setModalContent('')
			this.setShowModal(false)
		},
		confirmDelete: function () {
			if (this.getModalContent === 'deleteQuestion') {
				this.removeQuestion(this.getActiveQuestion.id).then((res) => {
					if (res.status === 'error') {
						this.setShowSnackbar(res.msg)
					} else {
						this.setModalContent('')
						this.setShowModal(false)
					}
				})
			} else if (this.getModalContent === 'deleteSection') {
				console.log('delete section')
				this.removeSection(this.getModifyingSection.id).then((res9) => {
					console.log(res9)
					if (res9.status === 'error') {
						this.setShowSnackbar(res9.msg)
					} else {
						console.log('else 1')
						this.setModalContent('')
						this.setShowModal(false)
						return res9
					}
				}).then(() => {
					console.log('then')
					this.getFormSections().then((res7) => {
						console.log('res 7')
						if (res7.payload.sections.length && res7.payload.sections.length > 0) {
							this.setActiveSectionId(res7.payload.sections[0].id)
						}
						this.setSections(res7)
					})
				})
			}
		}
	}
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
