import { FORMS_API_URL } from '@/env.ts'
import { ApiBase } from './ApiBase'
import store from '@/store/store.ts'
export default class FormsApi {
  private client: ApiBase
  private API_URL = FORMS_API_URL
  private headers = {
    'Content-Type': 'application/json;charset=UTF-8',
		'auth-token': store.getters.getUserToken
  }

  constructor() {
    // if (apiUrl) {
    //   this.API_URL = apiUrl
    // }
    this.client = new ApiBase(this.API_URL, this.headers)
  }
	getAllForms(search) {
		return this.client.get(`/api/v1/forms?page=${search.page}&limit=50&q.title=${search.search}`)
	}
  getSection(formId, sectionId) {
    return this.client.get(`/api/v1/submissions/forms/${formId}/?section_id=${sectionId}`)
  }
	getAllSections(formId) {
		return this.client.get(`/api/v1/sections/${formId}/?page=1&limit=100`)
	}
	getSectionQuestions(section_id) {
		return this.client.get(`/api/v1/questions?page=1&limit=50&section_id=${section_id}&with_options=true`)
	}
	createForm(formData) {
		return this.client.post(`/api/v1/forms`, formData)
	}
	createSection(layout) {
		return this.client.post(`/api/v1/sections`, layout)
	}
	createQuestion(question, section) {
		return this.client.post(`/api/v1/questions/${section}`, question)
	}
	updateQuestion(questionId, question) {
		return this.client.patch(`/api/v1/questions/${questionId}`, question)
	}
	deleteQuestion(id) {
		return this.client.delete(`/api/v1/questions/${id}`)
	}
	createOption(option) {
		return this.client.post(`/api/v1/options`, option)
	}
	publishForm(data) {
		return this.client.patch(`/api/v1/forms/${data.form}/publish`, data.status)
	}
	saveFormSettings(data) {
		return this.client.patch(`/api/v1/forms/${data.id}/settings`, data.body)
	}
	listQuestionOptions(id) {
		return this.client.get(`/api/v1/options/question/${id}`)
	}
	removeOption(id) {
		return this.client.delete(`/api/v1/options/${id}`)
	}
	updateOption(option) {
		return this.client.patch(`/api/v1/options/${option.id}`, option)
	}
	updateSection(section) {
		return this.client.patch(`/api/v1/sections/${section.id}`, section)
	}
	deleteSection(id) {
		return this.client.delete(`/api/v1/sections/${id}`)
	}
  answer(formId, data) {
    return this.client.post(`/api/v1/submissions/forms/${formId}/answer`, data)
  }
}
