<template>
  <div class="hello1">
		<router-link tag="button" :to="{name: 'Form Setup'}" class="flex justify-center items-center fixed bottom-8 right-8 object-right p-0 w-16 h-16 bg-red-600 rounded-full hover:bg-red-700 active:shadow-lg mouse shadow transition ease-in duration-200 focus:outline-none z-30">
			<svg viewBox="0 0 20 20" enable-background="new 0 0 20 20" class="w-6 h-6 inline-block">
				<path fill="#FFFFFF" d="M16,10c0,0.553-0.048,1-0.601,1H11v4.399C11,15.951,10.553,16,10,16c-0.553,0-1-0.049-1-0.601V11H4.601 C4.049,11,4,10.553,4,10c0-0.553,0.049-1,0.601-1H9V4.601C9,4.048,9.447,4,10,4c0.553,0,1,0.048,1,0.601V9h4.399 C15.952,9,16,9.447,16,10z" />
			</svg>
		</router-link>
		<div class="container mx-auto px-4 md:px-12">
			<div class="mt-2 relative rounded-md shadow-sm">
				<input
					type="text"
					v-model="searchInput"
					placeholder="Type to search..."
					class="shadow-lg form-input w-full px-3 py-2 appearance-none rounded-md focus:outline-none"
				/>
			</div>
		</div>
		<div class="container my-12 mx-auto px-4 md:px-12">
			<div class="flex flex-wrap -mx-1 lg:-mx-4">
				<div v-for="(form, index) in getAllForms" :key="`form${index}`" class="my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3">
					<article class="overflow-hidden rounded-lg shadow-lg bg-white border">
						<div class="flex justify-between items-center px-5 py-3 text-gray-700 border-b">
              <h3 class="text-sm"></h3>
						</div>
						<div class="flex flex-col px-5 py-6 bg-gray-100 text-gray-700 border-b">
							<label class="text-left mb-2 font-bold break-words">{{form.title}}</label>
							<div class="mt-2 mb-2 relative">
								<p class="inset-y-0 left-0 flex text-left text-gray-600 overflow-hidden">
									{{form.description}}
								</p>
								<p class="inset-y-0 mt-4 left-0 flex items-center text-gray-600 text-xs">
									Start: {{form.start_date}}
								</p>
								<p class="inset-y-0 left-0 flex items-center text-gray-600 text-xs">
									End: {{form.end_date}}
								</p>
              </div>
							<div v-if="form.active" class="text-left" >
								<label class="text-left mb-2 text-xs text-gray-600 break-words">Form URL:</label>
								<div class="flex flex-wrap items-stretch w-full mb-4 relative">
									<input disabled :value="`form.dev.unoapp.io/public/${getBusiness}/${form.slug}`" type="text" class="flex-shrink flex-grow flex-auto leading-normal w-px flex-1 border h-10 border-grey-light rounded rounded-r-none px-3 relative">
									<div class="flex -mr-px">
										<!--  -->
										<button @click="copyURL(form.slug)" class="flex items-center leading-normal bg-gray-100 hover:bg-red-100 rounded rounded-l-none border border-l-0 border-gray-500 px-3 whitespace-no-wrap text-grey-dark text-sm">
											<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
												<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3" />
											</svg>
										</button>
									</div>
								</div>
							</div>
            </div>
						<div class="flex border-t border-b border-gray-300">
							<div class="flex-1 inline-flex items-center h-16 px-6 hover:bg-gray-100">
								<button @click="editForm(form)" class="w-full justify-center flex items-center no-underline hover:underline text-black" href="#">
									<svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
										<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
									</svg>
									<p class="ml-2 text-sm capitalize">
										edit
									</p>
								</button>
							</div>
							<div class="flex-1 inline-flex items-center h-16 px-6 hover:bg-gray-100">
								<button @click="seeSettings(form)" class="w-full justify-center flex items-center no-underline hover:underline text-black" href="#">
									<svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
										<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4" />
									</svg>
									<p class="ml-2 text-sm capitalize">
										settings
									</p>
								</button>
							</div>
						</div>
						<div class="flex justify-between items-center px-5 py-3">
							<span class="ml-2 text-sm capitalize">publish</span>
							<div class="flex items-center mb-2 mt-4">
								<label  class="flex items-center cursor-pointer"  tabindex="0">
									<div class="relative">
										<input type="checkbox" :checked="form.active === true" class="hidden" @change="togglePublish($event, form)"/>
										<div class="toggle__line w-10 h-4 bg-gray-400 rounded-full shadow-inner"></div>
										<div class="toggle__dot absolute w-6 h-6 bg-white rounded-full shadow inset-y-0 left-0"></div>
									</div>
								</label>
							</div>
            </div>
          </article>
        </div>
			</div>
		</div>
		<div v-if="!noResults" class="px-5 py-5 border-t flex flex-col xs:flex-row items-center xs:justify-between">
			<div class="inline-flex mt-2 xs:mt-0">
				<button @click="formsPage('previous')"
						class="text-sm bg-gray-300 text-gray-800 font-semibold py-2 px-4 rounded-l"
						:class="isFirstPage ? '': 'hover:bg-gray-400'">
						Previous
				</button>
				<button @click="formsPage('next')"
						class="text-sm bg-gray-300 text-gray-800 font-semibold py-2 px-4 rounded-r"
						:class="isLastPage ? '': 'hover:bg-gray-400'">
						Next
				</button>
			</div>
		</div>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import {FORMS_PUBLIC_URL} from '@/env.ts'

export default {
	name: 'FormsMain',
	props: {},
	data() {return {
		forms: [],
		searchInput: '',
		currentPage: 1
	}},
	components: {},
	computed: {
		...mapGetters([
			'getName',
			'getAllForms',
			'getAllFormsPagination',
			'getBusiness'
		]),
		noResults() {
			if (this.getAllFormsPagination.lastPage === 0) {
				return true
			} else {
				return false
			}
		},
		isLastPage() {
			if (this.currentPage < this.getAllFormsPagination.lastPage) {
				return false
			} else {
				return true
			}
		},
		isFirstPage() {
			if (this.currentPage === 1) {
				return true
			} else {
				return false
			}
		}
	},
	watch: {
		forms () {},
		searchInput () {
			this.showForms()
		},
		currentPage () {
			this.showForms()
		}
	},
	created () {
	},
	mounted () {
		this.setModalContent('')
		this.setShowModal(false)
		this.setActiveSectionId('')
		this.setQuestions(false)
		this.setActiveFormId('')
		this.setActiveForm({})
		this.setSections(false)
		this.showForms()
	},
	methods: {
		...mapActions([
			'listAllForms',
			'publishUnpublishForm',
			'getFormSections',
			'getActiveSectionQuestions'
		]),
		...mapMutations([
			'setActiveForm',
			'setActiveFormOriginal',
			'setIsEdit',
			'setQuestions',
			'setActiveFormId',
			'setActiveSectionId',
			'setActiveForm',
			'setActiveFormEditable',
			'setShowSnackbar',
			'setShowSuccessSnackbar',
			'setAllForms',
			'setActiveSectionId',
			'setSections',
			'setIsCreatingNewForm',
			'setModalContent',
			'setShowModal'
		]),
		copyURL: function (slug) {
			// let link = 'form.dev.unoapp.io/public/' + slug
			let link = FORMS_PUBLIC_URL + this.getBusiness + '/' +  slug
			navigator.clipboard.writeText(link)
		},
		formsPage: function (direction) {
			if (direction === 'previous') {
				if (this.currentPage !== 1) {
					this.currentPage -= 1
				}
			} else {
				if (this.currentPage < this.getAllFormsPagination.lastPage) {
					this.currentPage += 1
				}
			}
		},
		showForms: function () {
			let formsParams = {
				'search': this.searchInput,
				'page': this.currentPage
			}
			this.listAllForms(formsParams).then((res) => {
				if (res.status === 'error') {
					this.setShowSnackbar(res.msg)
					let empty = {
						'payload': {
							'forms': [],
							'page': 0,
							'total_pages': 0
						}
					}
					this.setAllForms(empty)
				} else {
					this.setAllForms(res)
				}
			}).catch((err) => {
				console.log(err)
			})
		},
		editForm: function (form) {
			this.setIsEdit(true)
			this.setIsCreatingNewForm(false)
			this.setActiveFormId(form.id)
			this.setActiveForm(form)
			this.setActiveFormOriginal(form)
			this.setActiveFormEditable(!form.active)
			this.getFormSections().then((res) => {
				if (res.status !== 'error') {
					this.setSections(res)
					this.setActiveSectionId(res.payload.sections[0].id)
				}
				this.getActiveSectionQuestions()
				this.$router.push({name: 'questions'})
			})
		},
		togglePublish: function (e, form) {
			let active = 0
			if (e.target.checked === true) {
				active = 1
			} else {
				active = 0
			}
			let data = {
				'status': {
					'active': active
				},
				'form': form.id
			}
			this.publishUnpublishForm(data).then((res) => {
				if (res.status === 'error') {
					this.setShowSnackbar(res.msg)
					e.target.checked = !active
				} else {
					this.setShowSuccessSnackbar(res.status)
				}
			}).catch((err) => {
				console.log(err);
			})
		},
		seeSettings: function (form) {
			this.setActiveForm(form)
			this.setActiveFormOriginal(form)
			this.setActiveFormEditable(!form.active)
			this.setActiveFormId(form.id)
			this.$router.push('settings')
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  /* color: #42b983; */
}
.toggle__dot {
  top: -.25rem;
  left: -.25rem;
  transition: all 0.3s ease-in-out;
}

input:checked ~ .toggle__dot {
  transform: translateX(100%);
  background-color: #dc2626;
}
</style>
