<template>
	<div class="w-full h-full fixed block top-0 left-0 bg-white opacity-75 z-50">
		<div @click="setShowSpinner(false)" class="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-28 w-28 my-0 mx-auto block relative"></div>
	</div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import { defineComponent } from 'vue';
export default defineComponent ({
	name: 'Spinner',
	props: {},
	data: () => {
		return {}
	},
	components: {},
	computed: {
		...mapGetters([

		])
	},
	watch: {
	},
	created () {
	},
	mounted () {
	},
	methods: {
		...mapMutations([
			'setShowSpinner'
		])
	}
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.loader {
  border-top-color: #4B5563;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
	top: 50%;
  margin-top: -56px
}

@-webkit-keyframes spinner {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spinner {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
