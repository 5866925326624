// // auth middle ware to check if the user is logged in
export default function authCheck ({ store, next }){
	// console.log('testing auth middleware')
	if(store.getters.getUserToken){
		// console.log('IS LOGGED IN')
		return next()
	} else {
		// console.log('NOT LOGGED IN')
	}
}
  // const user = store.getters['auth/user']
  // eslint-disable-next-line no-extra-boolean-cast
  // let notLoggedIn = !Boolean(user)
  // if (notLoggedIn) {
  //   redirect({ name: 'Login' })
  // }
// }


// OR:

// auth middle ware to check if the user is logged in
// export const auth = function ({ to, next, store }) {
//   console.log('test')
//   const user = store.getters['auth/user'].token
//   let notLoggedIn = !user || user === undefined || user === null || user === ''
//   if (notLoggedIn) {
//     console.log('test2')
//
//     return next({
//       name: 'Login'
//     })
//   } else {
//     return next()
//   }
// }
