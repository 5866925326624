<template>
	<div>
		<div class="flex flex-col  pb-6 text-gray-700">
			<div class="mt-2 relative rounded-md shadow-sm">
				<div class="relative flex flex-col mt-2">
					<label class="text-xs text-left">Help Text</label>
					<input
						type="text"
						v-model="help_text"
						:disabled="!getIsActiveFormEditable"
						class="mt-2 form-input w-full px-3 py-2 appearance-none rounded-md focus:border-indigo-600 disabled:opacity-60"
					/>
				</div>
				<div class="relative flex flex-col mt-2">
					<label class="text-xs text-left">Ref Key</label>
					<input
						type="text"
						v-model="ref_key"
						:disabled="!getIsActiveFormEditable"
						class="mt-2 form-input w-full px-3 py-2 appearance-none rounded-md focus:border-indigo-600 disabled:opacity-60"
					/>
				</div>
				<div class="relative flex flex-col mt-2">
					<label class="text-xs text-left">Minimum Value</label>
					<input
						type="number"
						v-model="min"
						:disabled="!getIsActiveFormEditable"
						class="mt-2 form-input w-full px-3 py-2 appearance-none rounded-md focus:border-indigo-600 disabled:opacity-60"
					/>
				</div>
				<div class="relative flex flex-col mt-2">
					<label class="text-xs text-left">Maximum Value</label>
					<input
						type="number"
						v-model="max"
						:disabled="!getIsActiveFormEditable"
						class="mt-2 form-input w-full px-3 py-2 appearance-none rounded-md focus:border-indigo-600 disabled:opacity-60"
					/>
				</div>
				<div class="relative flex flex-col mt-2">
					<label class="text-xs text-left">Interval</label>
					<input
						type="number"
						v-model="interval"
						:disabled="!getIsActiveFormEditable"
						class="mt-2 form-input w-full px-3 py-2 appearance-none rounded-md focus:border-indigo-600 disabled:opacity-60"
					/>
				</div>
				<div class="relative flex flex-col mt-2">
					<label class="text-xs text-left">icon min</label>
					<input
						type="text"
						v-model="icon_min"
						:disabled="!getIsActiveFormEditable"
						class="mt-2 form-input w-full px-3 py-2 appearance-none rounded-md focus:border-indigo-600 disabled:opacity-60"
					/>
				</div>
				<div class="relative flex flex-col mt-2">
					<label class="text-xs text-left">icon max</label>
					<input
						type="text"
						v-model="icon_max"
						:disabled="!getIsActiveFormEditable"
						class="mt-2 form-input w-full px-3 py-2 appearance-none rounded-md focus:border-indigo-600 disabled:opacity-60"
					/>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { defineComponent } from 'vue';

export default defineComponent ({
	name: 'OptionFieldScale',
	props: {
		clear: {
			type: Boolean
		},
		currentlyEditing: {
			type: Object
		},
		addNew: {
			type: Boolean
		},
		index: {
			type: Number
		}
	},
	data: () => {
		return {
			help_text: '',
			min: 0,
			max: 0,
			interval: 0,
			icon_min: '',
			icon_max: '',
			ref_key: ''
		}
	},
	components: {},
	computed: {
		...mapGetters([
			'getActiveQuestionId',
			'getIsEdit',
			'getIsActiveFormEditable'
		])
	},
	watch: {
		help_text(value) {
			this.updateHelpText(value)
		},
		ref_key(value) {
			this.updateRefKey(value)
		},
		min(value) {
			this.updateMin(value)
		},
		max(value) {
			this.updateMax(value)
		},
		interval(value) {
			this.updateInt(value)
		},
		icon_min(value) {
			this.updateIconMin(value)
		},
		icon_max(value) {
			this.updateIconMax(value)
		},
		clear(value) {
			if (value === true) {
				this.help_text = ''
				this.min = 0
				this.max = 0
				this.interval = 0
				this.icon_min = ''
				this.icon_max = ''
				this.ref_key = ''
			}
		}
	},
	created () {
		this.$emit('validate', false)
		if (this.getIsEdit) {
			if (!this.addNew && this.currentlyEditing) {
				this.help_text = this.currentlyEditing.help_text
				this.min = this.currentlyEditing.config.min
				this.max = this.currentlyEditing.config.max
				this.interval = this.currentlyEditing.config.interval
				this.icon_min = this.currentlyEditing.config.icon_min
				this.icon_max = this.currentlyEditing.config.icon_max
				this.ref_key = this.currentlyEditing.ref
				this.$emit('validate', true)
			}
		}
	},
	mounted () {},
	methods: {
		...mapMutations([
			'setModalContent',
			'setShowModal',
			'closeModal',
			'setWorkingOption',
			'modifyActiveOption'
		]),
		...mapActions([
		]),
		updateHelpText: function (value) {
			let data = {
				'question_id': this.getActiveQuestionId,
				'type': 'scale',
				'order': 1,
				'help_text': value,
				'ref': this.ref_key,
				'config': {
					'type': 'slider',
					'min': +this.min,
					'max': +this.max,
					'interval': +this.interval,
					'icon_min': this.icon_min,
					'icon_max': this.icon_max
				}
			}
			if (+this.min < +this.max) {
				this.$emit('validate', true)
			} else {
				this.$emit('validate', false)
			}
			this.setWorkingOption(data)
			if (this.getIsEdit && this.currentlyEditing) {
				data.id = this.currentlyEditing.id
				if (+this.min < +this.max) {
					data.check = true
				} else {
					data.check = false
				}
				let info = {
					'index': this.index,
					'modified': data
				}
				this.modifyActiveOption(info)
			}
		},
		updateRefKey: function (value) {
			let data = {
				'question_id': this.getActiveQuestionId,
				'type': 'scale',
				'order': 1,
				'help_text': this.help_text,
				'ref': value,
				'config': {
					'type': 'slider',
					'min': +this.min,
					'max': +this.max,
					'interval': +this.interval,
					'icon_min': this.icon_min,
					'icon_max': this.icon_max
				}
			}
			if (+this.min < +this.max) {
				this.$emit('validate', true)
			} else {
				this.$emit('validate', false)
			}
			this.setWorkingOption(data)
			if (this.getIsEdit && this.currentlyEditing) {
				data.id = this.currentlyEditing.id
				if (+this.min < +this.max) {
					data.check = true
				} else {
					data.check = false
				}
				let info = {
					'index': this.index,
					'modified': data
				}
				this.modifyActiveOption(info)
			}
		},
		updateMin: function (value) {
			let data = {
				'question_id': this.getActiveQuestionId,
				'type': 'scale',
				'order': 1,
				'help_text': this.help_text,
				'ref': this.ref_key,
				'config': {
					'type': 'slider',
					'min': +value,
					'max': +this.max,
					'interval': +this.interval,
					'icon_min': this.icon_min,
					'icon_max': this.icon_max
				}
			}
			this.setWorkingOption(data)
			if (+value < +this.max) {
				this.$emit('validate', true)
			} else {
				this.$emit('validate', false)
			}
			if (this.getIsEdit && this.currentlyEditing) {
				data.id = this.currentlyEditing.id
				if (+value < +this.max) {
					data.check = true
				} else {
					data.check = false
				}
				let info = {
					'index': this.index,
					'modified': data
				}
				this.modifyActiveOption(info)
			}
		},
		updateMax: function (value) {
			let data = {
				'question_id': this.getActiveQuestionId,
				'type': 'scale',
				'order': 1,
				'help_text': this.help_text,
				'ref': this.ref_key,
				'config': {
					'type': 'slider',
					'min': +this.min,
					'max': +value,
					'interval': +this.interval,
					'icon_min': this.icon_min,
					'icon_max': this.icon_max
				}
			}
			this.setWorkingOption(data)
			if (+this.min < +value) {
				this.$emit('validate', true)
			} else {
				this.$emit('validate', false)
			}
			if (this.getIsEdit && this.currentlyEditing) {
				data.id = this.currentlyEditing.id
				if (+this.min < +value) {
					data.check = true
				} else {
					data.check = false
				}
				let info = {
					'index': this.index,
					'modified': data
				}
				this.modifyActiveOption(info)
			}
		},
		updateInt: function (value) {
			let data = {
				'question_id': this.getActiveQuestionId,
				'type': 'scale',
				'order': 1,
				'help_text': this.help_text,
				'ref': this.ref_key,
				'config': {
					'type': 'slider',
					'min': +this.min,
					'max': +this.max,
					'interval': +value,
					'icon_min': this.icon_min,
					'icon_max': this.icon_max
				}
			}
			this.setWorkingOption(data)
			if (+this.min < +this.max) {
				this.$emit('validate', true)
			} else {
				this.$emit('validate', false)
			}
			if (this.getIsEdit && this.currentlyEditing) {
				data.id = this.currentlyEditing.id
				if (+this.min < +this.max) {
					data.check = true
				} else {
					data.check = false
				}
				let info = {
					'index': this.index,
					'modified': data
				}
				this.modifyActiveOption(info)
			}
		},
		updateIconMin: function (value) {
			let data = {
				'question_id': this.getActiveQuestionId,
				'type': 'scale',
				'order': 1,
				'help_text': this.help_text,
				'ref': this.ref_key,
				'config': {
					'type': 'slider',
					'min': +this.min,
					'max': +this.max,
					'interval': this.interval,
					'icon_min': value,
					'icon_max': this.icon_max
				}
			}
			this.setWorkingOption(data)
			if (+this.min < +this.max) {
				this.$emit('validate', true)
			} else {
				this.$emit('validate', false)
			}
			if (this.getIsEdit && this.currentlyEditing) {
				data.id = this.currentlyEditing.id
				if (+this.min < +this.max) {
					data.check = true
				} else {
					data.check = false
				}
				let info = {
					'index': this.index,
					'modified': data
				}
				this.modifyActiveOption(info)
			}
		},
		updateIconMax: function (value) {
			let data = {
				'question_id': this.getActiveQuestionId,
				'type': 'scale',
				'order': 1,
				'help_text': this.help_text,
				'ref': this.ref_key,
				'config': {
					'type': 'slider',
					'min': +this.min,
					'max': +this.max,
					'interval': this.interval,
					'icon_min': this.icon_min,
					'icon_max': value
				}
			}
			this.setWorkingOption(data)
			if (+this.min < +this.max) {
				this.$emit('validate', true)
			} else {
				this.$emit('validate', false)
			}
			if (this.getIsEdit && this.currentlyEditing) {
				data.id = this.currentlyEditing.id
				if (+this.min < +this.max) {
					data.check = true
				} else {
					data.check = false
				}
				let info = {
					'index': this.index,
					'modified': data
				}
				this.modifyActiveOption(info)
			}
		}
	}
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.toggle__dot {
  top: -.25rem;
  left: -.25rem;
  transition: all 0.3s ease-in-out;
}

input:checked ~ .toggle__dot {
  transform: translateX(100%);
  background-color: #dc2626;
}
</style>
