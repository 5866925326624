// import { FORMS_API_URL } from '@/env.ts'
export interface ErrorRes {
    msg: string;
    status: any;
    [key: string]: any;
}
export class ApiBase {
    constructor(
        private API_URL: string,
        private headers: { [key: string]: string }) { }

    private async processApiResponse<T>(res: Response | void): Promise<T | (false | ErrorRes)> {
			console.log('processing api response')
			console.log(res)
        if (!res) {
            return false;
        }

        if (!res.ok) {
					let testing = await res.json()
					return testing as ErrorRes
            // return await res.json() as ErrorRes;
        }

        if (res.status < 200 || res.status > 299) {
					let testing = await res.json()
					return testing as ErrorRes
            // return await res.json() as ErrorRes;
        }

        const resJson = await res.json();

        // if (resJson.hasOwnProperty('error_id')) {
        //     return false;
        // }

        return resJson as T
    }

    async post<T, D>(endpoint: string, data: D): Promise<T | false | ErrorRes> {
        let res = await fetch(`${this.API_URL}${endpoint}`, {
            method: 'POST',
            headers: this.headers,
            body: JSON.stringify(data)
        }).catch((err) => {
            console.log('error making request: ', err.message);
        })

        let resJson = await this.processApiResponse<T>(res);

        return resJson
    }
		async patch<T, D>(endpoint: string, data: D): Promise<T | false | ErrorRes> {
        let res = await fetch(`${this.API_URL}${endpoint}`, {
            method: 'PATCH',
            headers: this.headers,
            body: JSON.stringify(data)
        }).catch((err) => {
            console.log('error making request: ', err.message);
        })

        let resJson = await this.processApiResponse<T>(res);

        return resJson
    }
		async delete<T>(endpoint: string): Promise<T | false | ErrorRes> {
        let res = await fetch(`${this.API_URL}${endpoint}`, {
            method: 'DELETE',
            headers: this.headers,
        }).catch((err) => {
            console.log('error making request: ', err.message);
        })

        let resJson = await this.processApiResponse<T>(res);

        if (!resJson) {
            return false;
        } else if ((resJson as ErrorRes).status === 'error') {
            return resJson;
        }

        return resJson;
    }
    async get<T>(endpoint: string): Promise<T | false | ErrorRes> {
        let res = await fetch(`${this.API_URL}${endpoint}`, {
            method: 'GET',
            headers: this.headers,
        }).catch((err) => {
            console.log('error making request: ', err.message);
        })

        let resJson = await this.processApiResponse<T>(res);

        if (!resJson) {
            return false;
        } else if ((resJson as ErrorRes).status === 'error') {
            return resJson;
        }

        return resJson;
    }
}
