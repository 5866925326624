<template>
	<div>
		<div class="flex flex-col  pb-6 text-gray-700">
			<div class="mt-2 relative rounded-md shadow-sm">
				<div class="relative flex flex-col mt-2">
					<label class="text-xs text-left">Help Text</label>
					<input
						type="text"
						v-model="help_text"
						:disabled="!getIsActiveFormEditable"
						class="mt-2 form-input w-full px-3 py-2 appearance-none rounded-md focus:border-indigo-600 disabled:opacity-60"
					/>
				</div>
				<div class="relative flex flex-col mt-2">
					<label class="text-xs text-left">Ref Key</label>
					<input
						type="text"
						v-model="ref_key"
						:disabled="!getIsActiveFormEditable"
						class="mt-2 form-input w-full px-3 py-2 appearance-none rounded-md focus:border-indigo-600 disabled:opacity-60"
					/>
				</div>
				<div class="relative flex flex-col mt-2">
					<label class="text-xs text-left">Address Line 1</label>
					<div class="flex items-center w-full mb-2 mt-4">
						<label v-if="currentlyEditing" key="editAddress" :for="`A${currentlyEditing.id}`" class="flex items-center cursor-pointer">
							<div class="relative">
								<input v-model="address_line_1" :id="`A${currentlyEditing.id}`" :disabled="!getIsActiveFormEditable" type="checkbox" class="hidden" />
								<div class="toggle__line w-10 h-4 bg-gray-400 rounded-full shadow-inner"></div>
								<div class="toggle__dot absolute w-6 h-6 bg-white rounded-full shadow inset-y-0 left-0"></div>
							</div>
						</label>
						<label v-else key="newAddressA" for="toogleA" class="flex items-center cursor-pointer">
							<div class="relative">
								<input v-model="address_line_1" id="toogleA" :disabled="!getIsActiveFormEditable" type="checkbox" class="hidden" />
								<div class="toggle__line w-10 h-4 bg-gray-400 rounded-full shadow-inner"></div>
								<div class="toggle__dot absolute w-6 h-6 bg-white rounded-full shadow inset-y-0 left-0"></div>
							</div>
						</label>
					</div>
				</div>
				<div class="relative flex flex-col mt-2">
					<label class="text-xs text-left">Address Line 2</label>
					<div class="flex items-center w-full mb-2 mt-4">
						<label v-if="currentlyEditing" key="editAddress" :for="`B${currentlyEditing.id}`" class="flex items-center cursor-pointer">
							<div class="relative">
								<input v-model="address_line_2" :id="`B${currentlyEditing.id}`" :disabled="!getIsActiveFormEditable" type="checkbox" class="hidden" />
								<div class="toggle__line w-10 h-4 bg-gray-400 rounded-full shadow-inner"></div>
								<div class="toggle__dot absolute w-6 h-6 bg-white rounded-full shadow inset-y-0 left-0"></div>
							</div>
						</label>
						<label v-else key="newAddressB" for="toogleB" class="flex items-center cursor-pointer">
							<div class="relative">
								<input v-model="address_line_2" id="toogleB" :disabled="!getIsActiveFormEditable" type="checkbox" class="hidden" />
								<div class="toggle__line w-10 h-4 bg-gray-400 rounded-full shadow-inner"></div>
								<div class="toggle__dot absolute w-6 h-6 bg-white rounded-full shadow inset-y-0 left-0"></div>
							</div>
						</label>
					</div>
				</div>
				<div class="relative flex flex-col mt-2">
					<label class="text-xs text-left">City</label>
					<div class="flex items-center w-full mb-2 mt-4">
						<label v-if="currentlyEditing" key="editAddress" :for="`C${currentlyEditing.id}`" class="flex items-center cursor-pointer">
							<div class="relative">
								<input v-model="city" :id="`C${currentlyEditing.id}`" :disabled="!getIsActiveFormEditable" type="checkbox" class="hidden" />
								<div class="toggle__line w-10 h-4 bg-gray-400 rounded-full shadow-inner"></div>
								<div class="toggle__dot absolute w-6 h-6 bg-white rounded-full shadow inset-y-0 left-0"></div>
							</div>
						</label>
						<label v-else key="newAddressC" for="toogleC" class="flex items-center cursor-pointer">
							<div class="relative">
								<input v-model="city" id="toogleC" :disabled="!getIsActiveFormEditable" type="checkbox" class="hidden" />
								<div class="toggle__line w-10 h-4 bg-gray-400 rounded-full shadow-inner"></div>
								<div class="toggle__dot absolute w-6 h-6 bg-white rounded-full shadow inset-y-0 left-0"></div>
							</div>
						</label>
					</div>
				</div>
				<div class="relative flex flex-col mt-2">
					<label class="text-xs text-left">Province</label>
					<div class="flex items-center w-full mb-2 mt-4">
						<label v-if="currentlyEditing" key="editAddress" :for="`D${currentlyEditing.id}`" class="flex items-center cursor-pointer">
							<div class="relative">
								<input v-model="province" :id="`D${currentlyEditing.id}`" :disabled="!getIsActiveFormEditable" type="checkbox" class="hidden" />
								<div class="toggle__line w-10 h-4 bg-gray-400 rounded-full shadow-inner"></div>
								<div class="toggle__dot absolute w-6 h-6 bg-white rounded-full shadow inset-y-0 left-0"></div>
							</div>
						</label>
						<label v-else key="newAddressD" for="toogleD" class="flex items-center cursor-pointer">
							<div class="relative">
								<input v-model="province" id="toogleD" :disabled="!getIsActiveFormEditable" type="checkbox" class="hidden" />
								<div class="toggle__line w-10 h-4 bg-gray-400 rounded-full shadow-inner"></div>
								<div class="toggle__dot absolute w-6 h-6 bg-white rounded-full shadow inset-y-0 left-0"></div>
							</div>
						</label>
					</div>
				</div>
				<div class="relative flex flex-col mt-2">
					<label class="text-xs text-left">Country</label>
					<div class="flex items-center w-full mb-2 mt-4">
						<label v-if="currentlyEditing" key="editAddress" :for="`E${currentlyEditing.id}`" class="flex items-center cursor-pointer">
							<div class="relative">
								<input v-model="country" :id="`E${currentlyEditing.id}`" :disabled="!getIsActiveFormEditable" type="checkbox" class="hidden" />
								<div class="toggle__line w-10 h-4 bg-gray-400 rounded-full shadow-inner"></div>
								<div class="toggle__dot absolute w-6 h-6 bg-white rounded-full shadow inset-y-0 left-0"></div>
							</div>
						</label>
						<label v-else key="newAddressE" for="toogleE" class="flex items-center cursor-pointer">
							<div class="relative">
								<input v-model="country" id="toogleE" :disabled="!getIsActiveFormEditable" type="checkbox" class="hidden" />
								<div class="toggle__line w-10 h-4 bg-gray-400 rounded-full shadow-inner"></div>
								<div class="toggle__dot absolute w-6 h-6 bg-white rounded-full shadow inset-y-0 left-0"></div>
							</div>
						</label>
					</div>
				</div>
				<div class="relative flex flex-col mt-2">
					<label class="text-xs text-left">Postal</label>
					<div class="flex items-center w-full mb-2 mt-4">
						<label v-if="currentlyEditing" key="editAddress" :for="`F${currentlyEditing.id}`" class="flex items-center cursor-pointer">
							<div class="relative">
								<input v-model="postal" :id="`F${currentlyEditing.id}`" :disabled="!getIsActiveFormEditable" type="checkbox" class="hidden" />
								<div class="toggle__line w-10 h-4 bg-gray-400 rounded-full shadow-inner"></div>
								<div class="toggle__dot absolute w-6 h-6 bg-white rounded-full shadow inset-y-0 left-0"></div>
							</div>
						</label>
						<label v-else key="newAddressF" for="toogleF" class="flex items-center cursor-pointer">
							<div class="relative">
								<input v-model="postal" id="toogleF" :disabled="!getIsActiveFormEditable" type="checkbox" class="hidden" />
								<div class="toggle__line w-10 h-4 bg-gray-400 rounded-full shadow-inner"></div>
								<div class="toggle__dot absolute w-6 h-6 bg-white rounded-full shadow inset-y-0 left-0"></div>
							</div>
						</label>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { defineComponent } from 'vue';

export default defineComponent ({
	name: 'OptionFieldAddress',
	props: {
		clear: {
			type: Boolean
		},
		currentlyEditing: {
			type: Object
		},
		addNew: {
			type: Boolean
		},
		index: {
			type: Number
		}
	},
	data: () => {
		return {
			help_text: '',
			address_line_1: false,
			address_line_2: false,
			city: false,
			province: false,
			country: false,
			postal: false,
			ref_key: ''
		}
	},
	components: {},
	computed: {
		...mapGetters([
			'getActiveQuestionId',
			'getIsEdit',
			'getIsActiveFormEditable'
		])
	},
	watch: {
		clear(value) {
			if (value === true) {
				this.help_text = '',
				this.ref_key = '',
				this.address_line_1 = false,
				this.address_line_2 = false,
				this.city = false,
				this.province = false,
				this.country = false,
				this.postal = false
			}
		},
		help_text(value) {
			this.updateHelpText(value)
		},
		ref_key(value) {
			this.updateRefKey(value)
		},
		address_line_1(value) {
			this.updateAddLine1(value)
		},
		address_line_2(value) {
			this.updateAddLine2(value)
		},
		city(value) {
			this.updateCity(value)
		},
		province(value) {
			this.updateProvince(value)
		},
		country(value) {
			this.updateCountry(value)
		},
		postal(value) {
			this.updatePostal(value)
		}
	},
	created () {
		this.$emit('validate', false)
		if (this.getIsEdit) {
			if (!this.addNew && this.currentlyEditing) {
				this.help_text = this.currentlyEditing.help_text
				this.ref_key = this.currentlyEditing.ref
				this.address_line_1 = this.currentlyEditing.config.required_keys.address_line_1
				this.address_line_2 = this.currentlyEditing.config.required_keys.address_line_2
				this.city = this.currentlyEditing.config.required_keys.city
				this.province = this.currentlyEditing.config.required_keys.province
				this.country = this.currentlyEditing.config.required_keys.country
				this.postal = this.currentlyEditing.config.required_keys.postal
				this.$emit('validate', true)
			}
		}
	},
	mounted () {},
	methods: {
		...mapMutations([
			'setModalContent',
			'setShowModal',
			'closeModal',
			'setWorkingOption',
			'modifyActiveOption'
		]),
		...mapActions([
		]),
		updateHelpText: function (value) {
			let data = {
				'question_id': this.getActiveQuestionId,
				'type': 'address',
				'order': 1,
				'help_text': value,
				'ref': this.ref_key,
				'config': {
					'required_keys': {
						'address_line_1': this.address_line_1,
						'address_line_2': this.address_line_2,
						'city': this.city,
						'province': this.province,
						'country': this.country,
						'postal': this.postal
					}
				}
			}
			this.$emit('validate', true)
			this.setWorkingOption(data)
			if (this.getIsEdit && this.currentlyEditing) {
				data.id = this.currentlyEditing.id
				data.check = true
				let info = {
					'index': this.index,
					'modified': data
				}
				this.modifyActiveOption(info)
			}
		},
		updateRefKey: function (value) {
			let data = {
				'question_id': this.getActiveQuestionId,
				'type': 'address',
				'order': 1,
				'help_text': this.help_text,
				'ref': value,
				'config': {
					'required_keys': {
						'address_line_1': this.address_line_1,
						'address_line_2': this.address_line_2,
						'city': this.city,
						'province': this.province,
						'country': this.country,
						'postal': this.postal
					}
				}
			}
			this.setWorkingOption(data)
			this.$emit('validate', true)
			if (this.getIsEdit && this.currentlyEditing) {
				data.id = this.currentlyEditing.id
				data.check = true
				let info = {
					'index': this.index,
					'modified': data
				}
				this.modifyActiveOption(info)
			}
		},
		updateAddLine1: function (value) {
			let data = {
				'question_id': this.getActiveQuestionId,
				'type': 'address',
				'order': 1,
				'help_text': this.help_text,
				'ref': this.ref_key,
				'config': {
					'required_keys': {
						'address_line_1': value,
						'address_line_2': this.address_line_2,
						'city': this.city,
						'province': this.province,
						'country': this.country,
						'postal': this.postal
					}
				}
			}
			this.setWorkingOption(data)
			this.$emit('validate', true)
			if (this.getIsEdit && this.currentlyEditing) {
				data.id = this.currentlyEditing.id
				data.check = true
				let info = {
					'index': this.index,
					'modified': data
				}
				this.modifyActiveOption(info)
			}
		},
		updateAddLine2: function (value) {
			let data = {
				'question_id': this.getActiveQuestionId,
				'type': 'address',
				'order': 1,
				'help_text': this.help_text,
				'ref': this.ref_key,
				'config': {
					'required_keys': {
						'address_line_1': this.address_line_1,
						'address_line_2': value,
						'city': this.city,
						'province': this.province,
						'country': this.country,
						'postal': this.postal
					}
				}
			}
			this.setWorkingOption(data)
			this.$emit('validate', true)
			if (this.getIsEdit && this.currentlyEditing) {
				data.id = this.currentlyEditing.id
				data.check = true
				let info = {
					'index': this.index,
					'modified': data
				}
				this.modifyActiveOption(info)
			}
		},
		updateCity: function (value) {
			let data = {
				'question_id': this.getActiveQuestionId,
				'type': 'address',
				'order': 1,
				'help_text': this.help_text,
				'ref': this.ref_key,
				'config': {
					'required_keys': {
						'address_line_1': this.address_line_1,
						'address_line_2': this.address_line_2,
						'city': value,
						'province': this.province,
						'country': this.country,
						'postal': this.postal
					}
				}
			}
			this.setWorkingOption(data)
			this.$emit('validate', true)
			if (this.getIsEdit && this.currentlyEditing) {
				data.id = this.currentlyEditing.id
				data.check = true
				let info = {
					'index': this.index,
					'modified': data
				}
				this.modifyActiveOption(info)
			}
		},
		updateProvince: function (value) {
			let data = {
				'question_id': this.getActiveQuestionId,
				'type': 'address',
				'order': 1,
				'help_text': this.help_text,
				'ref': this.ref_key,
				'config': {
					'required_keys': {
						'address_line_1': this.address_line_1,
						'address_line_2': this.address_line_2,
						'city': this.city,
						'province': value,
						'country': this.country,
						'postal': this.postal
					}
				}
			}
			this.setWorkingOption(data)
			this.$emit('validate', true)
			if (this.getIsEdit && this.currentlyEditing) {
				data.id = this.currentlyEditing.id
				data.check = true
				let info = {
					'index': this.index,
					'modified': data
				}
				this.modifyActiveOption(info)
			}
		},
		updateCountry: function (value) {
			let data = {
				'question_id': this.getActiveQuestionId,
				'type': 'address',
				'order': 1,
				'help_text': this.help_text,
				'ref': this.ref_key,
				'config': {
					'required_keys': {
						'address_line_1': this.address_line_1,
						'address_line_2': this.address_line_2,
						'city': this.city,
						'province': this.province,
						'country': value,
						'postal': this.postal
					}
				}
			}
			this.setWorkingOption(data)
			this.$emit('validate', true)
			if (this.getIsEdit && this.currentlyEditing) {
				data.id = this.currentlyEditing.id
				data.check = true
				let info = {
					'index': this.index,
					'modified': data
				}
				this.modifyActiveOption(info)
			}
		},
		updatePostal: function (value) {
			let data = {
				'question_id': this.getActiveQuestionId,
				'type': 'address',
				'order': 1,
				'help_text': this.help_text,
				'ref': this.ref_key,
				'config': {
					'required_keys': {
						'address_line_1': this.address_line_1,
						'address_line_2': this.address_line_2,
						'city': this.city,
						'province': this.province,
						'country': this.country,
						'postal': value
					}
				}
			}
			this.setWorkingOption(data)
			this.$emit('validate', true)
			if (this.getIsEdit && this.currentlyEditing) {
				data.id = this.currentlyEditing.id
				data.check = true
				let info = {
					'index': this.index,
					'modified': data
				}
				this.modifyActiveOption(info)
			}
		}
	}
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.toggle__dot {
  top: -.25rem;
  left: -.25rem;
  transition: all 0.3s ease-in-out;
}

input:checked ~ .toggle__dot {
  transform: translateX(100%);
  background-color: #dc2626;
}
</style>
