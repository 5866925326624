<template>
  <div class="hello1">
		<h1>{{$route.name}}</h1>
		<div class="w-full">
			<div class="mt-4">
				<div class="mt-4">
					<div class="w-full bg-white shadow-md rounded-md overflow-hidden border">
						<form @submit.prevent="compileForm">
							<div class="flex flex-col px-5 py-6 text-gray-700">
								<label class="text-xs text-left">Name</label>
								<div class="mt-2 relative rounded-md shadow-sm">
									<input v-model="newForm.name" type="text" class="form-input w-full px-3 py-2 appearance-none rounded-md focus:border-indigo-600"/>
								</div>
							</div>
							<div class="flex flex-col px-5 py-6 text-gray-700 border-b">
								<label class="text-xs text-left">Description</label>
								<div class="mt-2 relative rounded-md shadow-sm">
									<textarea v-model="newForm.description" id="description" name="description" rows="3" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border-gray-500 rounded-md" placeholder="optional"></textarea>
								</div>
							</div>
							<div class="flex flex-col px-5 py-6 text-gray-700 border-b">
								<label class="text-xs text-left">Start Date</label>
								<div class="mt-2 relative rounded-md shadow-sm">
									<input v-model="newForm.date" type="date" class="form-input w-full px-3 py-2 appearance-none rounded-md focus:border-indigo-600"/>
								</div>
							</div>
							<div class="flex justify-end items-center px-5 py-3">
								<button type="submit"
								:disabled="validationError"
								:class="validationError ? 'bg-red-200': 'bg-red-600 hover:bg-red-500'"
								class="px-3 py-1 text-white rounded-md text-sm  focus:outline-none text-base">
									Save
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
  </div>
</template>
<script>
import { mapMutations, mapActions } from 'vuex'
import { defineComponent } from 'vue';
export default defineComponent ({
	name: 'FormSetup',
	props: {},
	data: () => {
		return {
			newForm: {
				name: '',
				description: '',
				date: null
			}
		}
	},
	components: {},
	computed: {
		dateFuture() {
			let today = new Date().toLocaleDateString('en-CA')
			const x = new Date(today)
			const y = new Date(this.newForm.date)
			if (+y > +x){
				return true
			} else {
				return false
			}
		},
		validationError() {
			if (this.newForm.name === '' || this.dateFuture !== true) {
				return true
			} else {
				return false
			}
		}
	},
	watch: {
	},
	created () {
		this.setIsEdit(false)
	},
	mounted () {
	},
	methods: {
		...mapMutations([
			'setActiveFormId',
			'setIsEdit',
			'setIsCreatingNewForm',
			'setShowSnackbar',
			'setShowSuccessSnackbar'
		]),
		...mapActions([
			'createForm'
		]),
		compileForm: function () {
			let data = {
				'title': this.newForm.name,
				'description': this.newForm.description,
				'start_date': this.newForm.date
			}
			this.createForm(data).then((res) => {
				if (res.status === 'error') {
					this.setShowSnackbar(res.msg)
				} else if (res.status === 'success') {
					this.setShowSuccessSnackbar(res.status)
					this.setActiveFormId(res.payload.id)
					this.setIsCreatingNewForm(true)
					this.$router.push({ name: 'questions', params: {id: res.payload.id} })
				} else {
					this.setShowSnackbar('error')
				}
			})
		}
	}
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  /* color: #42b983; */
}
</style>
