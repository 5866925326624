<template>
	<ul class="w-full">
		<!-- Items Section -->
		<router-link v-for="(route, index) in $router.options.routes" :key="`route${index}`" tag="li" :to="route.path" :class="route.meta.showInNav ? '' : 'hidden'">
			<a class="h-16 px-6 flex justify-between items-center w-full
			focus:text-orange-500 flex-shrink-0 hover:bg-gray-100">
				<svg
					class="h-5 w-5"
					xmlns="http://www.w3.org/2000/svg"
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
					stroke="currentColor"
					stroke-width="2"
					stroke-linecap="round"
					stroke-linejoin="round">
					<path :d="route.meta.svgPath"></path>
				</svg>
				<span :class="getMainSidebarOpen ? '' : 'hidden'" class="flex-grow capitalize">{{route.name}}</span>
			</a>
		</router-link>
	</ul>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
export default {
	name: 'SidebarMain',
	props: {},
	data() {
		return {}
	},
	components: {},
	computed: {
		...mapGetters([
			'getMainSidebarOpen'
		])
	},
	watch: {},
	created () {},
	mounted () {},
	methods: {
		...mapMutations([
			'setSidebarOpen',
		])
	}
};
</script>
