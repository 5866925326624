<template>
  <div class="hello1">
		<button :disabled="noSections || (getIsEdit && !getIsActiveFormEditable)" @click="openModal"  :class="noSections || (getIsEdit && !getIsActiveFormEditable) ? 'bg-red-200': 'bg-red-600 hover:bg-red-700'"
		class="flex justify-center items-center fixed bottom-8 right-8 object-right p-0 w-16 h-16 rounded-full active:shadow-lg mouse shadow transition ease-in duration-200 focus:outline-none">
			<svg viewBox="0 0 20 20" enable-background="new 0 0 20 20" class="w-6 h-6 inline-block">
				<path fill="#FFFFFF" d="M16,10c0,0.553-0.048,1-0.601,1H11v4.399C11,15.951,10.553,16,10,16c-0.553,0-1-0.049-1-0.601V11H4.601 C4.049,11,4,10.553,4,10c0-0.553,0.049-1,0.601-1H9V4.601C9,4.048,9.447,4,10,4c0.553,0,1,0.048,1,0.601V9h4.399 C15.952,9,16,9.447,16,10z" />
			</svg>
		</button>
		<!-- <div v-if="getIsEdit && getIsActiveFormEditable && getSections.length > 0" class="mb-6">
			<button @click="editSectionConfig" class="w-full mt-3 px-3 py-1 bg-red-600 text-white rounded-md text-sm hover:bg-red-500 focus:outline-none flex justify-center items-center">
				<svg class="w-6 h-6 inline-block mx-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="#ffffff">
					<path fill="none" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
				</svg>
				<p class="text-base">edit section config</p>
			</button>
		</div> -->
		<div class="w-full">
		<draggable
			v-model="myList"
			group="people"
			@start="drag=true"
			@end="drag=false"
			item-key="id"
			class="list-group"
			v-bind="dragOptions"
      tag="transition-group"
			:component-data="{name:'fade'}">
			<template #item="{element}">
				<div class="my-5 px-4 py-4 rounded-lg shadow-lg bg-white max-w-xl m-auto flex list-group-item">
					<div class="flex flex-1 items-center">
						<svg class="w-6 h-6 inline-block" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="#9CA3AF">
							<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 10h16M4 14h16M4 18h16" />
						</svg>
						<p class="flex inline-flex mx-4">{{element.title}}</p>
					</div>
					<!-- add a "view only" button if !getIsActiveFormEditable to open edit modal but with everything disabled -->
					<button v-if="!getIsActiveFormEditable" @click="editQuestion(element)" class="w-8 h-8 bg-gray-400 rounded-full hover:bg-gray-700 active:shadow-lg mouse shadow transition ease-in duration-200 focus:outline-none">
						<svg class="w-6 h-6 inline-block" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="#ffffff">
							<path stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
							<path stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
						</svg>
					</button>
					<button v-if="(getIsEdit && getIsActiveFormEditable) || getIsCreatingNewForm" @click="editQuestion(element)" class="w-8 h-8 bg-gray-400 rounded-full hover:bg-gray-700 active:shadow-lg mouse shadow transition ease-in duration-200 focus:outline-none">
						<svg class="w-6 h-6 inline-block" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="#ffffff">
							<path fill="none" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
						</svg>
					</button>
					<button v-if="(getIsEdit && getIsActiveFormEditable) || getIsCreatingNewForm" @click="deleteQuestion(element)" class="ml-2 w-8 h-8 bg-gray-400 rounded-full hover:bg-gray-700 active:shadow-lg mouse shadow transition ease-in duration-200 focus:outline-none">
						<svg class="w-6 h-6 inline-block" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="#ffffff">
							<path fill="none" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
						</svg>
					</button>
				</div>
			</template>
		</draggable>
			<div>
				<div>
					<div class="mt-5 md:mt-0 md:col-span-2">
					</div>
				</div>
			</div>
		</div>
  </div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { defineComponent} from 'vue';
import draggable from 'vuedraggable'

export default defineComponent ({
	name: 'FormQuestions',
	props: {},
	components: {
		draggable
	},
	computed: {
		...mapGetters([
			'getActiveFormId',
			'getActiveSectionId',
			'getQuestions',
			'getIsActiveFormEditable',
			'getIsEdit',
			'getSections',
			'getIsCreatingNewForm'
		]),
		myList: {
			get() {
					return this.$store.state.questions
			},
			set(value) {
				this.$store.dispatch('reorderQuestions', value)
			}
		},
		dragOptions() {
			return {
				animation: 200,
				group: 'description',
				disabled: false,
				ghostClass: 'ghost'
			}
		},
		noSections() {
			if (this.getSections.length > 0) {
				return false
			} else {
				return true
			}
		}
	},
	watch: {
		getActiveSectionId() {
			this.getActiveSectionQuestions()
		}
	},
	created () {
		this.getActiveSectionQuestions()
	},
	mounted () {
	},
	methods: {
		...mapMutations([
			'setModalContent',
			'setShowModal',
			'setActiveQuestion',
			'setActiveQuestionId',
			'setActiveQuestionIdDirect',
			'setModifyingSection',
			'setIsEdit'
		]),
		...mapActions([
			'getActiveSectionQuestions',
			'reorderQuestions'

		]),
		editSectionConfig: function () {
			for (let x = 0; x < this.getSections.length; x++) {
				if (this.getSections[x].id === this.getActiveSectionId) {
					this.setModifyingSection(this.getSections[x])
					this.setModalContent('sectionEdit')
					this.setShowModal(true)
				}
			}
		},
		editQuestion: function (question) {
			this.setIsEdit(true)
			this.setActiveQuestionIdDirect(question.id)
			this.setActiveQuestion(question)
			this.setModalContent('questionEdit')
			this.setShowModal(true)
		},
		deleteQuestion: function (question) {
			this.setModalContent('deleteQuestion')
			this.setShowModal(true)
			this.setActiveQuestionIdDirect(question.id)
			this.setActiveQuestion(question)
		},
		openModal: function () {
			this.setModalContent('questionNew')
			this.setShowModal(true)
		}
	}
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #9CA3AF;
}
.list-group {
  min-height: 20px;
}
.list-group-item {
  cursor: move;
}
.list-group-item i {
  cursor: pointer;
}
</style>
