<template>
	<div>
		<div
			class="flex justify-between items-center px-5 py-3 text-gray-700 border-b"
		>
			<h3 class="text-sm">Layout Configuration</h3>
			<button @click="closeModal">
				<svg
					class="h-4 w-4"
					fill="none"
					viewBox="0 0 24 24"
					stroke="currentColor"
				>
					<path
						stroke-linecap="round"
						stroke-linejoin="round"
						stroke-width="2"
						d="M6 18L18 6M6 6l12 12"
					/>
				</svg>
			</button>
		</div>
		<div class="flex flex-col px-5 py-6 bg-gray-200 text-gray-700 border-b">
			<label class="text-xs text-left">Section Title</label>
			<div class="mt-2 relative rounded-md shadow-sm">
				<input
					type="text"
					v-model="title"
					:disabled="!getIsActiveFormEditable"
					class="form-input w-full px-3 py-2 appearance-none rounded-md focus:border-indigo-600"
				/>
			</div>
		</div>
		<div class="flex flex-col px-5 py-6 bg-gray-200 text-gray-700 border-b">
			<label class="text-xs text-left">Section Order</label>
			<div class="mt-2 relative rounded-md shadow-sm">
				<div class="relative">
					<select
					v-model="order"
					:disabled="!getIsActiveFormEditable"
						class="appearance-none h-full rounded border block appearance-none w-full bg-white border-gray-500 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
					>
						<option v-for="(section, index) in getSections" :key="`sectionOrder${index}`">{{index + 1}}</option>
					</select>
				</div>
			</div>
		</div>
		<div v-if="showEditLayout === 'table'" class="bg-gray-200 flex flex-col px-5 py-6 text-gray-700">
			<label class="text-xs text-left">Select All</label>
			<div class="flex items-center w-full mb-2 mt-4">
				<label class="flex items-center cursor-pointer">
					<div class="relative">
						<input v-model="select_all" type="checkbox" class="hidden" />
						<div class="toggle__line w-10 h-4 bg-gray-400 rounded-full shadow-inner"></div>
						<div class="toggle__dot absolute w-6 h-6 bg-white rounded-full shadow inset-y-0 left-0"></div>
					</div>
				</label>
			</div>
		</div>
		<ModalSectionEditGrid v-if="showEditLayout === 'grid'" @selectLayout="setGridLayout" />
		<ModalSectionEditTable v-if="showEditLayout === 'table'" @something="setTableLayout" />
		<!-- component -->
		<div class="flex justify-between items-center px-5 py-3">
			<button
				@click="deleteSection"
				:disabled="!getIsActiveFormEditable"
				class="px-3 py-1 border border-red-600 text-red-600 text-sm rounded-md hover:bg-red-100 focus:outline-none flex justify-center items-center">
				<svg class="w-6 h-6 inline-block mr-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
					<path fill="none" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"/>
				</svg>
				<p class="text-base capitalize">delete section</p>
			</button>
			<button
			@click="saveChanges"
			:disabled="totalValidationError || !getIsActiveFormEditable"
				class="px-3 py-1  text-white rounded-md text-sm focus:outline-none text-base"
				:class="totalValidationError ? 'bg-red-200': 'bg-red-600 hover:bg-red-500'"
			>
				Save Changes
			</button>
		</div>
	</div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { defineComponent } from 'vue';
import ModalSectionEditGrid from './ModalSectionEditGrid.vue';
import ModalSectionEditTable from './ModalSectionEditTable.vue';

export default defineComponent ({
	name: 'ModalSectionEdit',
	props: {},
	data: () => {
		return {
			title: '',
			order: '',
			gridConfig: '1-1',
			select_all: false,
			titleValidationError: true,
			showValidationError: true,
			tableInfo: {},
			submitReq: false,
			showEditLayout: ''
		}
	},
	components: {
		ModalSectionEditGrid,
		ModalSectionEditTable
		// , ModalSectionLayoutConfigTable
	},
	computed: {
		...mapGetters([
			'getModalContent',
			'getModifyingSection',
			'getIsActiveFormEditable',
			'getSections'
		]),
		totalValidationError() {
			if (this.titleValidationError === true || this.showValidationError === true) {
				return true
			} else {
				return false
			}
		}
	},
	watch: {
		title(value) {
			if (value === '') {
				this.titleValidationError = true
			} else {
				this.titleValidationError = false
				if (this.showValidationError) {
					this.validation(true)
				} else {
					this.validation(false)
				}
			}
		}
	},
	created () {
		this.title = this.getModifyingSection.title
		this.order = this.getModifyingSection.order
		this.select_all = this.getModifyingSection.layout_config.select_all
		this.showEditLayout = this.getModifyingSection.layout
	},
	mounted () {},
	methods: {
		...mapMutations([
			'setModalContent',
			'setShowModal',
			'setActiveSectionId',
			'closeModal',
			'setShowSnackbar',
			'setModifyingSecton',
			'setSections',
			'setShowSuccessSnackbar'
		]),
		...mapActions([
			'confirmCreateSection',
			'getFormSections',
			'updateSection'
		]),
		validation: function (error) {
			if (error) {
				this.showValidationError = true
			} else {
				this.showValidationError = false
			}
		},
		setGridLayout: function (layout) {
			this.gridConfig = layout
			this.showValidationError = false
		},
		setTableLayout: function (layout) {
			this.gridConfig = layout.headers
			this.showValidationError = !layout.valid
		},
		deleteSection: function () {
			this.setModalContent('deleteSection')
			this.setShowModal(true)
		},
		saveChanges: function () {
			let info = {}
			if (this.showEditLayout === 'table') {
				this.submitReq = true
				info = {
					'id': this.getModifyingSection.id,
					'title': this.title,
					'order': this.order,
					'select_all': this.select_all,
					'layout_config': {
						'table_headers': this.gridConfig
					}
				}
			} else {
				info = {
					'id': this.getModifyingSection.id,
					'layout': 'grid',
					'title': this.title,
					'order': this.order,
					'layout_config': {
						'style': this.gridConfig
					}
				}
			}
			this.updateSection(info).then((res) => {
				if (res.status === 'error') {
					this.setShowSnackbar(res.msg)
				} else {
					this.setModalContent('')
					this.setShowModal(false)
					this.setShowSuccessSnackbar(res.status)
					return res
				}
			}).then((res1) => {
				this.setActiveSectionId(res1.payload.id)
			}).then(() => {
				this.getFormSections().then((res3) => {
					this.setSections(res3)
				})
			})
		}
	}
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.toggle__dot {
  top: -.25rem;
  left: -.25rem;
  transition: all 0.3s ease-in-out;
}

input:checked ~ .toggle__dot {
  transform: translateX(100%);
  background-color: #dc2626;
}
</style>
