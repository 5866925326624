<template>
  <div class="hello">
    submissions page
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapMutations } from 'vuex'

export default defineComponent({
  name: 'Submissions',
  props: {
    msg: String,
  },
	created () {
		this.setActiveSectionId('')
		this.setQuestions(false)
		this.setActiveFormId('')
		this.setActiveForm({})
		this.setSections(false)
	},
	methods: {
		...mapMutations([
			'setActiveSectionId',
			'setQuestions',
			'setActiveFormId',
			'setActiveForm',
			'setSections'
		]),
	}
 });
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
