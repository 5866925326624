// import Vue from 'vue'
// import Router from 'vue-router'
import { createRouter, createWebHistory } from 'vue-router'
import authCheck from './middlewares/auth'
import store from '@/store/store'
// routes
import Submissions from '@/components/Submissions.vue'
import FormsMain from '@/components/FormsMain.vue'
import FormSetup from '@/components/FormSetup.vue'
import FormQuestions from '@/components/FormQuestions.vue'
import FormSettings from '@/components/FormSettings.vue'

const routerHistory = createWebHistory()

// Vue.use(Router)

const router = createRouter({
  history: routerHistory,
  routes: [
    {
      path: '/',
			redirect: '/forms',
      // component: FormsMain,
			// name: 'forms',
			meta: {
				showInNav: false,
				showSidebarMain: true,
				showSidebarSections: false,
				showSidebarTotal: true,
				middleware: [authCheck]
			}
    },
		{
			path: '/forms',
			component: FormsMain,
			name: 'forms',
			meta: {
				showInNav: true,
				showSidebarMain: true,
				showSidebarSections: false,
				showSidebarTotal: true,
				svgPath: 'M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01',
				middleware: [authCheck]
			}
		},
    {
      path: '/submissions',
      component: Submissions,
			name: 'submissions',
			meta: {
				showInNav: true,
				showSidebarMain: true,
				showSidebarSections: false,
				showSidebarTotal: true,
				svgPath: 'M20 13V6a2 2 0 00-2-2H6a2 2 0 00-2 2v7m16 0v5a2 2 0 01-2 2H6a2 2 0 01-2-2v-5m16 0h-2.586a1 1 0 00-.707.293l-2.414 2.414a1 1 0 01-.707.293h-3.172a1 1 0 01-.707-.293l-2.414-2.414A1 1 0 006.586 13H4',
				middleware: [authCheck]
			}
    },
		{
      path: '/new',
      component: FormSetup,
			name: 'Form Setup',
			meta: {
				showInNav: false,
				showSidebarMain: false,
				showSidebarSections: false,
				showSidebarTotal: false,
				middleware: [authCheck]
			}
    },
		{
			path: '/section',
			component: FormQuestions,
			name: 'questions',
			meta: {
				showInNav: false,
				showSidebarMain: false,
				showSidebarSections: true,
				showSidebarTotal: true,
				middleware: [authCheck]
			}
		},
		{
			path: '/settings',
			component: FormSettings,
			name: 'settings',
			meta: {
				showInNav: false,
				showSidebarMain: true,
				showSidebarSections: false,
				showSidebarTotal: true,
				middleware: [authCheck]
			}
		},
		{
			path: '/:pathMatch(.*)*',
			redirect: '/forms',
			meta: {
				showInNav: false,
				showSidebarMain: false,
				showSidebarSections: false,
				showSidebarTotal: false,
				// middleware: [authCheck]
			}
		}
  ]
})

router.beforeEach((to, from, next) => {
    if (!to.meta.middleware) {
        return next()
    }
    const middleware = to.meta.middleware

    const context = {
        to,
        from,
        next,
        store
    }
    return middleware[0]({
        ...context
    })
})

export default router
