<template>
	<div class="flex h-screen bg-gray-200 font-roboto">
		<Spinner v-if="getShowSpinner" />
		<Snackbar v-if="getShowSnackbar"/>
		<SnackbarSuccess v-if="getShowSuccessSnackbar"/>
		<Modal :class="`${
			!getShowModal && 'opacity-0 pointer-events-none'}`"  class="modal"/>
		<Sidebar v-if="$route.meta.showSidebarTotal" />
		<div class="flex-1 flex flex-col overflow-hidden">
			<Header />
			<main class="flex-1 overflow-x-hidden overflow-y-auto bg-gray-200">
				<div class="container mx-auto px-6 py-8">
					<router-view v-slot="{ Component }">
						<transition name="fade" mode="out-in">
							<component :is="Component"/>
							<!--  @event-test="$emit('new-test-event')" -->
						</transition>
					</router-view>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import Sidebar from './components/Sidebar.vue'
import Header from './components/Header.vue'
import Modal from './components/modals/Modal.vue'
import Spinner from './components/Spinner.vue'
import Snackbar from './components/Snackbar.vue'
import SnackbarSuccess from './components/SnackbarSuccess.vue'
import { mapMutations, mapActions, mapGetters } from 'vuex'

export default {
  name: 'App',
	components: {
    Header,
    Sidebar,
		Modal,
		Spinner,
		Snackbar,
		SnackbarSuccess
  },
	data() {
		return {
			isLoading: true
		}
	},
	computed: {
		...mapGetters([
			'getShowModal',
			'getUserToken',
			'getShowSpinner',
			'getShowSnackbar',
			'getShowSuccessSnackbar'
		])
	},
	watch: {},
	created () {
		// check URL for auth token
		// if no auth token param, check vuex
		// if no auth token then show "please login" link to unoapp auth site
		var allParams = new URLSearchParams(window.location.search)
		if (allParams.has('token')) {
			var tokenParam = allParams.get('token')
			this.setUserToken(tokenParam)
			this.getUserName().then((res) => {
				this.setUserName(res.payload.user.first_name)
				console.log(res.payload.active_business.id)
				console.log(res.payload)
				this.getBusinessInfo(res.payload.active_business.id).then((res1) => {
					console.log('business info:')
					console.log(res1)
					this.setBusinessInfo(res1.payload.slug)
				})
			})
		} else {
			if (!this.getUserToken) {
				this.setShowSnackbar('please login')
			}
		}
		this.setShowModal(false)
		this.setShowSpinner(false)

	},
	mounted () {},
	methods: {
		...mapMutations([
			'setUserToken',
			'setUserName',
			'setShowModal',
			'setShowSpinner',
			'setShowSnackbar',
			'setBusinessInfo'
		]),
		...mapActions([
			'getUserName',
			'getBusinessInfo'
		])
	}
}
</script>

<style>
.modal {
  transition: opacity 0.25s ease;
}
.spinner {
  transition: opacity 0.25s ease;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
}
</style>
