<template>
	<div>
		<div class="flex flex-col px-5 pb-6 bg-gray-200 text-gray-700 border-b">
			<label class="text-xs text-left">Configuration</label>
			<div class=" relative rounded-md shadow-sm">
				<div class="relative">
					<select
					:disabled="!getIsActiveFormEditable"
					v-model="selectedConfig"
						class="appearance-none h-full rounded border block appearance-none w-full bg-white border-gray-500 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
					>
						<option v-for="(option, index) in configurations" :key="`option${index}`">{{option}}</option>
					</select>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { defineComponent } from 'vue';

export default defineComponent ({
	name: 'ModalSectionEditGrid',
	props: {},
	data: () => {
		return {
			selectedConfig: '',
			configurations: [
				'1-1',
				'1-2',
				'1-3',
				'1-4',
				'1-5',
				'1-6',
				'2-1',
				'2-2',
				'2-3',
				'2-4',
				'2-5',
				'2-6',
				'3-1',
				'3-2',
				'3-3',
				'3-4',
				'3-5',
				'3-6',
				'4-1',
				'4-2',
				'4-3',
				'4-4',
				'4-5',
				'4-6',
				'5-1',
				'5-2',
				'5-3',
				'5-4',
				'5-5',
				'5-6',
				'6-1',
				'6-2',
				'6-3',
				'6-4',
				'6-5',
				'6-6'
			]
		}
	},
	components: {
	},
	computed: {
		...mapGetters([
			'getModifyingSection',
			'getIsActiveFormEditable'
		])
	},
	watch: {
		selectedConfig(value) {
			this.$emit('selectLayout', value)
		}
	},
	created () {
	},
	mounted () {
		this.selectedConfig = this.getModifyingSection.layout_config.style
	},
	methods: {
		...mapMutations([]),
		...mapActions([])
	}
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
