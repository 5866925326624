// export const FORMS_API_URL='https://forms2.dev.api.unoapp.io'
// export const FORMS_API_TOKEN='24532268e527bfd9c211f5aacd9b782498e297ce'
// uat:
// export const FORMS_API_URL='https://forms-app-2.uat.api.unoapp.io/'
// production:
export const FORMS_API_URL='https://forms-app.api.unoapp.io'

// export const AUTH_UNOAPP_URL='https://auth.dev.api.unoapp.io'
// export const AUTH_UNOAPP_TOKEN='6090a6ee31d7d70b13857118a7ead5d6a5d83a8a'
// uat:
// export const AUTH_UNOAPP_URL='https://auth.uat.api.unoapp.io'
// export const AUTH_UNOAPP_TOKEN='fcb745eed149f13a845bcb84a35a10b04c268cdc'
// production:
export const AUTH_UNOAPP_URL='https://auth.api.unoapp.io'
export const AUTH_UNOAPP_TOKEN='e7f283c293794fa709a9a64d650be26eed404bfa'

// export const FORMS_PUBLIC_URL='form.dev.unoapp.io/public/'
// uat:
// export const FORMS_PUBLIC_URL='form.uat.unoapp.io/public/'
// production:
export const FORMS_PUBLIC_URL='form.unoapp.io/public/'
