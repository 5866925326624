import { AUTH_UNOAPP_URL, AUTH_UNOAPP_TOKEN } from '@/env.ts'
import { ApiBase } from './ApiBase'
import store from '@/store/store.ts'
export default class AuthApi {
  private client: ApiBase
  private API_URL = AUTH_UNOAPP_URL
  private headers = {
    'Content-Type': 'application/json;charset=UTF-8',
		'auth-token': store.getters.getUserToken,
		'api-token': AUTH_UNOAPP_TOKEN
  }
  constructor() {
    this.client = new ApiBase(this.API_URL, this.headers)
  }
	getUserProfile() {
		return this.client.get(`/api/v1/users/profile`)
	}
	getBusinessProfile(id) {
		return this.client.get(`/api/v1/businesses/${id}?withBranding=false`)
	}
	logoutUser() {
		return this.client.post(`/api/v1/users/logout`, {})
	}
}
