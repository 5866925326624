<template>
  <header
    class="flex justify-between items-center py-4 px-6 bg-white border-b-4 border-red-500"
  >
    <div class="flex items-center">
      <router-link tag="button" :to="{'name': 'forms'}"
				v-if="$route.meta.showSidebarSections || !$route.meta.showSidebarTotal"
        class="text-gray-500 focus:outline-none mr-8"
      >
        <svg
          class="h-6 w-6"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 19l-7-7m0 0l7-7m-7 7h18"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </router-link>

			<div v-if="$route.meta.showSidebarSections || !$route.meta.showSidebarTotal">Forms</div>
      <div v-else class="relative mx-4 lg:mx-0 capitalize">
				{{$route.name}}
      </div>
    </div>

    <div class="flex items-center">
      <div class="relative">
				<button @click="dropdownOpen = !dropdownOpen" class="px-6 border cursor-pointer  py-3 bg-white rounded-md text-gray-700 font-medium tracking-wide hover:bg-gray-100 flex align-center">
					<span>{{getUserName}}</span>
					<svg class="h-5 w-5 text-gray-800 ml-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
						<path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
					</svg>
				</button>
				<div v-if="dropdownOpen" class="absolute right-0 mt-2 py-2 w-48 border bg-white rounded-md shadow-xl z-20 font-medium tracking-wide hover:bg-gray-100">
					<button v-if="dropdownOpen" @click="logoutUser" class="w-full">
						<p class="py-2 text-sm capitalize">
							Logout
						</p>
					</button>
				</div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
// import { ref } from "vue";
// import { useSidebar } from "../hooks/useSidebar";

export default {
	name: 'Header',
	props: {},
	data() {
		return {
			dropdownOpen: false
		}
	},
	components: {},
	computed: {
		...mapGetters([
			'getMainSidebarOpen',
			'getUserName'
		])
	},
	watch: {},
	created () {},
	mounted () {},
	methods: {
		...mapMutations([
			'setSidebarOpen',
			'setShowSnackbar'
		]),
		...mapActions([
			'logout'
		]),
		logoutUser: function () {
			this.logout().then((res) => {
				if (res.status === 'error' || res.error_id) {
					this.setShowSnackbar(res.msg)
				} else {
					if (res.redirect_to) {
						window.location.href = res.redirect_to
					}
				}
			})
		}
	}
};
</script>
