import { createStore } from "vuex"
import createPersistedState from "vuex-persistedstate"
import FormsApi from '@/global/apis/FormsApi'
import AuthApi from '@/global/apis/AuthApi'

const store = createStore({
   state:{
      name: "Testing 123",
			user: {
				token: '',
				name: ''
			},
			business: '',
			sidebarMainOpen: true,
			activeFormId: '',
			activeForm: {},
			activeFormOriginal: {},
			activeSectionId: '',
			activeQuestionId: '',
			activeQuestion: {},
			sections: [],
			questions: [],
			showModal: false,
			modalContent: '',
			testSec: '',
			workingOption: null,
			isEdit: false,
			isActiveFormEditable: false,
			activeOptions: [],
			showSpinner: true,
			allForms: [],
			allFormsPagination: {},
			showSnackbar: false,
			snackbarMessage: '',
			showSuccessSnackbar: false,
			snackbarSuccessMessage: '',
			isCreatingNewForm: true,
			modifyingOriginalOptions : [{}],
			modifyingSection: {}
		},
		getters: {
			getName: state => state.name,
			getMainSidebarOpen: state => state.sidebarMainOpen,
			getUserToken: state => state.user.token,
			getUserName: state => state.user.name,
			getBusiness: state => state.business,
			getActiveFormId: state => state.activeFormId,
			getActiveSectionId: state => state.activeSectionId,
			getActiveQuestionId: state => state.activeQuestionId,
			getSections: state => state.sections,
			getQuestions: state => state.questions,
			getShowModal: state => state.showModal,
			getModalContent: state => state.modalContent,
			getWorkingOption: state => state.workingOption,
			getActiveForm: state => state.activeForm,
			getActiveFormOriginal: state => state.activeFormOriginal,
			getIsEdit: state => state.isEdit,
			getIsActiveFormEditable: state => state.isActiveFormEditable,
			getActiveQuestion: state => state.activeQuestion,
			getActiveOptions: state => state.activeOptions,
			getShowSpinner: state => state.showSpinner,
			getAllForms: state => state.allForms,
			getAllFormsPagination: state => state.allFormsPagination,
			getShowSnackbar: state => state.showSnackbar,
			getSnackbarMessage: state => state.snackbarMessage,
			getShowSuccessSnackbar: state => state.showSuccessSnackbar,
			getSnackbarSuccessMessage: state => state.snackbarSuccessMessage,
			getIsCreatingNewForm: state => state.isCreatingNewForm,
			getModifyingOriginalOptions: state => state.modifyingOriginalOptions,
			getModifyingSection: state => state.modifyingSection
		},
		mutations: {
			setSidebarOpen (state, payload) {
				state.sidebarMainOpen = payload
			},
			setUserToken (state, payload) {
				state.user.token = payload
			},
			setUserName (state, payload) {
				state.user.name = payload
			},
			setBusinessInfo (state, payload) {
				state.business = payload
			},
			setActiveFormId (state, payload) {
				state.activeFormId = payload
			},
			setActiveSectionId (state, payload) {
				state.activeSectionId = payload
			},
			setActiveQuestionId (state, payload) {
				state.activeQuestionId = payload.payload.id
			},
			setActiveQuestionIdDirect (state, payload) {
				state.activeQuestionId = payload
			},
			setSections (state, payload) {
				if (payload === false) {
					state.sections = []
					state.activeSectionId = ''
				} else {
					state.sections = payload.payload.sections
				}
			},
			reorderQuestionsMut (state, list) {
				state.questions = list
			},
			setQuestions (state, payload) {
				if (payload === false || !payload.payload) {
					state.questions = []
				} else {
					state.questions = payload.payload.questions
				}
			},
			setShowModal (state, payload) {
				state.showModal = payload
			},
			setModalContent (state, payload) {
				state.modalContent = payload
			},
			testSections (state, payload) {
				state.testSec = payload
			},
			closeModal (state) {
				state.showModal = false
				state.modalContent = ''
			},
			setWorkingOption (state, payload) {
				state.workingOption = payload
			},
			setActiveForm (state, payload) {
				state.activeForm = payload
			},
			setActiveFormOriginal (state, payload) {
				state.activeFormOriginal = payload
			},
			setIsEdit (state, payload) {
				state.isEdit = payload
			},
			setActiveFormEditable (state, payload) {
				state.isActiveFormEditable = payload
			},
			setActiveQuestion (state, payload) {
				state.activeQuestion = payload
			},
			setActiveOptions (state, payload) {
				state.activeOptions = payload
			},
			setShowSpinner (state, payload) {
				state.showSpinner = payload
			},
			setAllForms (state, payload) {
				state.allForms = payload.payload.forms
				let pagination = {
					'currentPage': payload.payload.page,
					'lastPage': payload.payload.total_pages
				}
				state.allFormsPagination = pagination
			},
			setShowSnackbar (state, message) {
				state.showSnackbar = true
				if (message.msg) {
					state.snackbarMessage = message.msg
				} else {
					state.snackbarMessage = message
				}
				setTimeout( function() {
					state.showSnackbar = false
					state.snackbarMessage = ''
				}, 3000)
			},
			setShowSuccessSnackbar (state, message) {
				state.showSuccessSnackbar = true
				if (message.msg) {
					state.snackbarSuccessMessage = message.msg
				} else {
					state.snackbarSuccessMessage = message
				}
				setTimeout( function() {
					state.showSuccessSnackbar = false
					state.snackbarSuccessMessage = ''
				}, 3000)
			},
			dismissSnackbar (state) {
				state.showSnackbar = false
				state.snackbarMessage = ''
			},
			dismissSuccessSnackbar (state) {
				state.showSuccessSnackbar = false
				state.snackbarSuccessMessage = ''
			},
			setIsCreatingNewForm (state, mode) {
				state.isCreatingNewForm = mode
			},
			modifyActiveOption (state, data) {
				state.modifyingOriginalOptions[data.index] = data.modified
			},
			setModifyingOriginalOptions (state, data) {
				state.modifyingOriginalOptions = data
			},
			setModifyingSection (state, data) {
				state.modifyingSection = data
			}
		},
		actions: {
			async listAllForms (context, payload) {
				let forms = new FormsApi()
				try {
					context.commit('setShowSpinner', true)
					let searchParams = {
						'search': '',
						'page': 1
					}
					if (payload.search) {
						searchParams.search = payload.search
					}
					if (payload.page) {
						searchParams.page = +payload.page
					}
					const allForms = await forms.getAllForms(searchParams)
					// context.commit('setAllForms', allForms)
					return allForms
				} catch (error) {
					console.log(error)
				} finally {
					context.commit('setShowSpinner', false)
				}
			},
			getUserName () {
				let user = new AuthApi()
				const userInfo = user.getUserProfile()
				return userInfo
			},
			getBusinessInfo (context, id) {
				let user = new AuthApi()
				const businessInfo = user.getBusinessProfile(id)
				return businessInfo
			},
			async getFormSections (context) {
				let forms = new FormsApi()
				context.commit('setShowSpinner', true)
				try {
					let sections = await forms.getAllSections(context.getters.getActiveFormId)
					// context.commit('setSections', sections)
					return sections
				} catch (error) {
					console.log(error)
				} finally {
					context.commit('setShowSpinner', false)
				}
			},
			async confirmCreateSection (context, payload) {
				let body = {}
				if (context.getters.getModalContent === 'selectSectionLayoutConfig-grid') {
					body = {
						'form_id': context.getters.getActiveFormId,
						'layout': 'grid',
						'title': payload.title,
						'layout_config': {
							'style': payload.layout
						},
						'order': context.getters.getSections.length + 1
					}
				} else if (context.getters.getModalContent === 'selectSectionLayoutConfig-table') {
					body = {
						'form_id': context.getters.getActiveFormId,
						'layout': 'table',
						'title': payload.title,
						'layout_config': {
							'select_all': payload.select_all,
							'table_headers': payload.table_headers
						}
					}
				}
				let forms = new FormsApi()
				context.commit('setShowSpinner', true)
				try {
					const created = await forms.createSection(body)
					return created
				} catch (error) {
					console.log(error)
				} finally {
					context.commit('setShowSpinner', false)
				}
			},
			async getActiveSectionQuestions (context) {
				let forms = new FormsApi()
				context.commit('setShowSpinner', true)
				try {
					let questions = await forms.getSectionQuestions(context.getters.getActiveSectionId)
					context.commit('setQuestions', questions)
					return questions
				} catch (error) {
					context.commit('setQuestions', {})
					console.log(error)
				} finally {
					context.commit('setShowSpinner', false)
				}
			},
			async createOption (context, payload) {
				let forms = new FormsApi()
				context.commit('setShowSpinner', true)
				try {
					let option = await forms.createOption(payload)
					return option
				} catch (error) {
					console.log(error)
				} finally {
					context.commit('setShowSpinner', false)
				}
			},
			async createQuestion (context, payload) {
				let forms = new FormsApi()
				context.commit('setShowSpinner', true)
				try {
					let question = await forms.createQuestion(payload, context.getters.getActiveSectionId)
					// context.dispatch('getActiveSectionQuestions')
					// context.commit('setActiveQuestionId', question)
					console.log(question)
					return question
				} catch (error) {
					console.log(error)
				} finally {
					context.commit('setShowSpinner', false)
				}
			},
			// setNewQuestionsOrder (context, payload) {
			// 	for (var i = 1; i < context.getters.getQuestions.length + 1; i++) {
			// 		var obj = context.getters.getQuestions[i]
			// 		var newObj = obj
			// 		newObj.order = i
			// 	}
			// },
			checkRes (context, res) {
				if (res.status === 'error') {
					return false
				} else {
					return true
				}
			},
			async reorderQuestions (context, list) {
				let changedOrder = []
				for (let i = 0; i < list.length; i++) {
					list[i].order = i + 1
					let forms = new FormsApi()
					context.commit('setShowSpinner', true)
					try {
						let ques = await forms.updateQuestion(list[i].id, list[i])
						context.dispatch('checkRes', ques).then((res1) => {
								if (res1 === false) {
									context.dispatch('getActiveSectionQuestions')
									context.commit('setShowSpinner', false)
									context.commit('setShowSnackbar', ques)
									console.log('error')
								} else {
									context.commit('reorderQuestionsMut', list)
								}
						})
					} catch (error) {
						context.dispatch('getActiveSectionQuestions')
						context.commit('setShowSpinner', false)
						// show snack bar
						context.commit('setShowSnackbar', error)
					} finally {
						context.commit('setShowSpinner', false)

					}
				}
			},
			async publishUnpublishForm (context, data) {
				let forms = new FormsApi()
				try {
					let res = await forms.publishForm(data)
					context.dispatch('checkResponseForError', res)
					context.dispatch('listAllForms', res)
					return res
				} catch (error) {
					console.log(error)
				}
			},
			async saveFormSettings (context, data) {
				let forms = new FormsApi()
				context.commit('setShowSpinner', true)
				try {
					let formData = {
						'id': context.getters.getActiveForm.id,
						'body': data
					}
					let settingsUpdate = await forms.saveFormSettings(formData)
					return settingsUpdate
				} catch (error) {
					console.log(error)
				} finally {
					context.commit('setShowSpinner', false)
				}
			},
			async updateQuestion (context, data) {
				let forms = new FormsApi()
				try {
					let update = await forms.updateQuestion(context.getters.getActiveQuestion.id, data)
					return update
				} catch (error) {
					console.log(error)
				}
			},
			async listQuestionOptions (context) {
				let forms = new FormsApi()
				context.commit('setShowSpinner', true)
				try {
					let options = await forms.listQuestionOptions(context.getters.getActiveQuestion.id)
					return options
				} catch (error) {
					console.log(error)
				} finally {
					context.commit('setShowSpinner', false)
				}
			},
			async removeOption (context, id) {
				let forms = new FormsApi()
				context.commit('setShowSpinner', true)
				try {
					let options = await forms.removeOption(id)
					return options
				} catch (error) {
					console.log(error)
				} finally {
					context.commit('setShowSpinner', false)
				}
			},
			async updateOption (context, modified) {
				let forms = new FormsApi()
				try {
					let option = await forms.updateOption(modified)
					return option
				} catch (error) {
					console.log(error)
				} finally {
					context.commit('setShowSpinner', false)
				}
			},
			checkResponseForError (state, data) {
				if (data.status === 'error') {
					state.commit('setShowSnackbar', data.msg)
				}
			},
			createForm (context, data) {
				let forms = new FormsApi()
				context.commit('setShowSpinner', true)
				try {
					let created = forms.createForm(data)
					return created
				} catch (error) {
					console.log(error)
					return false
				} finally {
					context.commit('setShowSpinner', false)
				}
			},
			async updateSection (context, data) {
				let forms = new FormsApi()
				context.commit('setShowSpinner', true)
				try {
					let modified = forms.updateSection(data)
					return modified
				} catch (error) {
					console.log(error)
					return false
				} finally {
					context.commit('setShowSpinner', false)
				}
			},
			async removeSection (context, id) {
				let forms = new FormsApi()
				context.commit('setShowSpinner', true)
				try {
					let sections = await forms.deleteSection(id)
					return sections
				} catch (error) {
					console.log(error)
				} finally {
					context.commit('setShowSpinner', false)
				}
			},
			async removeQuestion (context, id) {
				let forms = new FormsApi()
				context.commit('setShowSpinner', true)
				try {
					let questions = await forms.deleteQuestion(id)
					context.dispatch('getActiveSectionQuestions')
					return questions
				} catch (error) {
					console.log(error)
				} finally {
					context.commit('setShowSpinner', false)
				}
			},
			async logout (context) {
				let user = new AuthApi()
				try {
					const authRes = user.logoutUser()
					return authRes
				} catch (error) {
					console.log(error)
				} finally {
					context.commit('setShowSpinner', false)
				}
			},
		},
		plugins: [
			createPersistedState()
		]
})

export default store
