<template>
	<div>
		<div
			class="flex justify-between items-center px-5 py-3 text-gray-700 border-b"
		>
			<h3 class="text-sm">Layout Configuration</h3>
			<button @click="closeModal">
				<svg
					class="h-4 w-4"
					fill="none"
					viewBox="0 0 24 24"
					stroke="currentColor"
				>
					<path
						stroke-linecap="round"
						stroke-linejoin="round"
						stroke-width="2"
						d="M6 18L18 6M6 6l12 12"
					/>
				</svg>
			</button>
		</div>
		<div class="flex flex-col px-5 py-6 bg-gray-200 text-gray-700 border-b">
			<label class="text-xs text-left">Section Title</label>

			<div class="mt-2 relative rounded-md shadow-sm">
				<input
					type="text"
					v-model="title"
					class="form-input w-full px-3 py-2 appearance-none rounded-md focus:border-indigo-600"
				/>
			</div>
		</div>
		<ModalSectionLayoutConfigGrid v-if="getModalContent === 'selectSectionLayoutConfig-grid'" @selectLayout="setGridLayout" />
		<ModalSectionLayoutConfigTable v-if="getModalContent === 'selectSectionLayoutConfig-table'" :submitReq="submitReq" @validationCheck="validation" @tableData="setTableData" />
		<!-- component -->
		<div class="flex justify-between items-center px-5 py-3">
			<button
			@click="changeModalContent"
				class="px-3 py-1 text-gray-700 text-sm rounded-md bg-gray-200 hover:bg-gray-300 focus:outline-none text-base"
			>
				Back
			</button>
			<button
			@click="confirmSectionCreate"
			:disabled="totalValidationError"
				class="px-3 py-1  text-white rounded-md text-sm focus:outline-none text-base"
				:class="totalValidationError ? 'bg-red-200': 'bg-red-600 hover:bg-red-500'"
			>
				Confirm
			</button>
		</div>
	</div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { defineComponent } from 'vue';
import ModalSectionLayoutConfigGrid from './ModalSectionLayoutConfigGrid.vue'
import ModalSectionLayoutConfigTable from './ModalSectionLayoutConfigTable.vue'

export default defineComponent ({
	name: 'ModalSectionLayoutConfig',
	props: {},
	data: () => {
		return {
			title: '',
			gridConfig: '1-1',
			titleValidationError: true,
			showValidationError: true,
			tableInfo: {},
			submitReq: false
		}
	},
	components: {
		ModalSectionLayoutConfigGrid,
		ModalSectionLayoutConfigTable
	},
	computed: {
		...mapGetters([
			'getModalContent'
		]),
		totalValidationError() {
			if (this.titleValidationError === true || this.showValidationError === true) {
				return true
			} else {
				return false
			}
		}
	},
	watch: {
		title(value) {
			if (value === '') {
				this.titleValidationError = true
			} else {
				this.titleValidationError = false
				if (this.showValidationError) {
					this.validation(true)
				} else {
					this.validation(false)
				}
			}
		}
	},
	created () {},
	mounted () {},
	methods: {
		...mapMutations([
			'setModalContent',
			'setShowModal',
			'setActiveSectionId',
			'closeModal',
			'setShowSnackbar',
			'setSections',
			'setShowSuccessSnackbar'
		]),
		...mapActions([
			'confirmCreateSection',
			'getFormSections'
		]),
		validation: function (error) {
			if (error) {
				this.showValidationError = true
			} else {
				this.showValidationError = false
			}
		},
		setGridLayout: function (layout) {
			this.gridConfig = layout
			this.showValidationError = false
		},
		setTableData: function (info) {
			// reformat table info
			let table_headers = []
			for (let x = 0; x < info.names.length; x++) {
				let newHeader = {
					'name': info.names[x],
					'type': info.types[x]
				}
				table_headers.push(newHeader)
				if (x === info.names.length - 1) {
					// this.tableInfo = table_headers
					let data = {
						'table_headers': table_headers,
						'select_all': info.select_all
					}
					this.confirmSectionCreateTable(data)
				}
			}
		},
		confirmSectionCreateTable: function (data) {
			let info = {}
			info.title = this.title
			info.table_headers = data.table_headers
			info.select_all = data.select_all
			this.confirmCreateSection(info).then((res) => {
				if (res.status === 'error') {
					this.setShowSnackbar(res.msg)
				} else {
					this.setShowSuccessSnackbar(res.status)
					this.setModalContent('')
					this.setShowModal(false)
					return res
				}
			}).then((res1) => {
				this.setActiveSectionId(res1.payload.id)
			}).then(() => {
				this.getFormSections().then((res3) => {
					this.setSections(res3)
				})
			})
		},
		confirmSectionCreate: function () {
			if (this.getModalContent === 'selectSectionLayoutConfig-table') {
				this.submitReq = true
			} else {
				let info = {}
				if (this.getModalContent === 'selectSectionLayoutConfig-grid') {
					info.title = this.title
					info.layout = this.gridConfig
				}
				this.confirmCreateSection(info).then((res) => {
					if (res.status === 'error') {
						this.setShowSnackbar(res.msg)
					} else {
						this.setModalContent('')
						this.setShowSuccessSnackbar(res.status)
						this.setShowModal(false)
						return res
					}
				}).then((res1) => {
					this.setActiveSectionId(res1.payload.id)
				}).then(() => {
					this.getFormSections().then((res3) => {
						this.setSections(res3)
					})
				})
			}
		},
		changeModalContent: function () {
			this.setModalContent('selectSectionLayout')
			this.setShowModal(true)
		}
	}
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
