<template>
	<div>
		<div
			class="flex justify-between items-center px-5 py-3 text-gray-700"
		>
			<h3 class="text-sm">options</h3>
			<button @click="closeModal">
				<svg
					class="h-4 w-4"
					fill="none"
					viewBox="0 0 24 24"
					stroke="currentColor"
				>
					<path
						stroke-linecap="round"
						stroke-linejoin="round"
						stroke-width="2"
						d="M6 18L18 6M6 6l12 12"
					/>
				</svg>
			</button>
		</div>
		<div class="flex flex-col px-5 pb-6 bg-gray-200 text-gray-700 border-b">
			<label class="text-xs text-left">Option Type</label>
			<div class="mt-2 relative rounded-md shadow-sm">
				<div class="relative">
					<select
					v-model="option_type"
						class="appearance-none h-full rounded border block appearance-none w-full bg-white border-gray-500 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
					>
						<option v-for="(option, index) in options" :key="`option${index}`">{{option}}</option>
					</select>
				</div>
			</div>
			<div class="mt-2">
				<div v-if="option_type === ''">
					please select an option type
				</div>
				<OptionFieldInteger v-if="option_type === 'integer'" :clear="clear" @validate="checkValid"/>
				<OptionFieldCurrency v-if="option_type === 'currency'" :clear="clear" @validate="checkValid"/>
				<OptionFieldText v-if="option_type === 'text'" :clear="clear" @validate="checkValid"/>
				<OptionFieldTextArea v-if="option_type === 'textarea'" :clear="clear" @validate="checkValid"/>
				<OptionFieldPassword v-if="option_type === 'password'" :clear="clear" @validate="checkValid"/>
				<OptionFieldShowImage v-if="option_type === 'show image'" :clear="clear" @validate="checkValid"/>
				<OptionFieldButton v-if="option_type === 'button'" :clear="clear" @validate="checkValid"/>
				<OptionFieldImageNotes v-if="option_type === 'image notes'" :clear="clear" @validate="checkValid"/>
				<OptionFieldAddress v-if="option_type === 'address'" :clear="clear" @validate="checkValid"/>
				<OptionFieldScale v-if="option_type === 'scale'" :clear="clear" @validate="checkValid"/>
				<OptionFieldSignature v-if="option_type === 'signature'" :clear="clear" @validate="checkValid"/>
				<OptionFieldDateTime v-if="option_type === 'datetime'" :clear="clear" @validate="checkValid"/>
				<OptionFieldCalculatedField v-if="option_type === 'calculated'" :clear="clear" @validate="checkValid"/>
				<OptionFieldSelectSingle v-if="option_type === 'select (single)'" :clear="clear" @validate="checkValid"/>
				<OptionFieldSelectMultiple v-if="option_type === 'select (multiple)'" :clear="clear" @validate="checkValid"/>
				<OptionFieldDropDown v-if="option_type === 'dropdown'" :clear="clear" @validate="checkValid"/>
				<OptionFieldPhoneNumber v-if="option_type === 'phone number'" :clear="clear" @validate="checkValid"/>
				<OptionFieldNote v-if="option_type === 'note'" :clear="clear" @validate="checkValid"/>
				<!-- components -->
			</div>
		</div>
		<div class="flex justify-between items-center px-5 py-3">
			<button @click="finishOption('stay')"
				:disabled="!validation"
				class="px-3 py-1 text-white text-sm rounded-md  focus:outline-none"
				:class="!validation ? 'bg-red-200': 'bg-red-600 hover:bg-red-500'"
			>
				Create and Add
			</button>
			<button @click="finishOption('done')"
				:disabled="!validation"
				class="px-3 py-1 text-white rounded-md text-sm focus:outline-none"
				:class="!validation ? 'bg-red-200': 'bg-red-600 hover:bg-red-500'"
			>
				Create and Done
			</button>
		</div>
	</div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { defineComponent } from 'vue';
import OptionFieldInteger from '../optionFields/optionFieldInteger.vue'
import OptionFieldCurrency from '../optionFields/optionFieldCurrency.vue'
import OptionFieldText from '../optionFields/optionFieldText.vue'
import OptionFieldTextArea from '../optionFields/optionFieldTextArea.vue'
import OptionFieldPassword from '../optionFields/optionFieldPassword.vue'
import OptionFieldShowImage from '../optionFields/optionFieldShowImage.vue'
import OptionFieldButton from '../optionFields/optionFieldButton.vue'
import OptionFieldImageNotes from '../optionFields/optionFieldImageNotes.vue'
import OptionFieldAddress from '../optionFields/optionFieldAddress.vue'
import OptionFieldScale from '../optionFields/optionFieldScale.vue'
import OptionFieldSignature from '../optionFields/optionFieldSignature.vue'
import OptionFieldDateTime from '../optionFields/optionFieldDateTime.vue'
import OptionFieldCalculatedField from '../optionFields/optionFieldCalculatedField.vue'
import OptionFieldSelectSingle from '../optionFields/optionFieldSelectSingle.vue'
import OptionFieldSelectMultiple from '../optionFields/optionFieldSelectMultiple.vue'
import OptionFieldDropDown from '../optionFields/optionFieldDropDown.vue'
import OptionFieldPhoneNumber from '../optionFields/optionFieldPhoneNumber.vue'
import OptionFieldNote from '../optionFields/optionFieldNote.vue'

export default defineComponent ({
	name: 'ModalQuestionNewAddOptions',
	props: {},
	data: () => {
		return {
			clear: false,
			option_type: '',
			options: [
				'integer',
				'currency',
				'text',
				'textarea',
				'password',
				'show image',
				'button',
				'image notes',
				'address',
				'scale',
				'signature',
				'datetime',
				'calculated',
				'select (single)',
				'select (multiple)',
				'dropdown',
				'phone number',
				'note'
			],
			validation: false
		}
	},
	components: {
		OptionFieldInteger,
		OptionFieldCurrency,
		OptionFieldText,
		OptionFieldTextArea,
		OptionFieldPassword,
		OptionFieldShowImage,
		OptionFieldButton,
		OptionFieldImageNotes,
		OptionFieldAddress,
		OptionFieldScale,
		OptionFieldSignature,
		OptionFieldDateTime,
		OptionFieldCalculatedField,
		OptionFieldSelectSingle,
		OptionFieldSelectMultiple,
		OptionFieldDropDown,
		OptionFieldPhoneNumber,
		OptionFieldNote
	},
	computed: {
		...mapGetters([
			'getWorkingOption'
		])
	},
	watch: {
		validation(value) {
			if (value === true) {
				return true
			} else {
				return false
			}
		}
	},
	created () {},
	mounted () {},
	methods: {
		...mapMutations([
			'setModalContent',
			'setShowModal',
			'closeModal',
			'setWorkingOption',
			'setShowSnackbar',
			'setShowSuccessSnackbar'
		]),
		...mapActions([
			'createOption'
		]),
		checkValid: function (check) {
			if (check === true) {
				this.validation = true
			} else {
				this.validation = false
			}
		},
		finishOption: function (next) {
			this.createOption(this.getWorkingOption).then((res) => {
				if (res.status === 'error') {
					this.setShowSnackbar(res.msg)
					console.log('error')
				} else {
					this.setShowSuccessSnackbar(res.status)
					if (next === 'done') {
						this.clear = true
						this.setWorkingOption(null)
						this.setModalContent('')
						this.setShowModal(false)
					} else if (next === 'stay') {
						this.option_type = ''
						this.clear = true
					}
				}
			})
		}
	}
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.toggle__dot {
  top: -.25rem;
  left: -.25rem;
  transition: all 0.3s ease-in-out;
}

input:checked ~ .toggle__dot {
  transform: translateX(100%);
  background-color: #dc2626;
}
</style>
