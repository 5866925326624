<template>
		<div class="flex flex-col px-5 pb-6 bg-gray-200 text-gray-700 border-b">
			<!-- <div class="flex flex-col py-6 text-gray-700">
				<label class="text-xs text-left">Select All</label>
				<div class="flex items-center w-full mb-2 mt-4">
					<label class="flex items-center cursor-pointer">
						<div class="relative">
							<input v-model="select_all" type="checkbox" class="hidden" />
							<div class="toggle__line w-10 h-4 bg-gray-400 rounded-full shadow-inner"></div>
							<div class="toggle__dot absolute w-6 h-6 bg-white rounded-full shadow inset-y-0 left-0"></div>
						</div>
					</label>
				</div>
			</div> -->
			<label class="text-xs text-left">Configure Table</label>
			<div class="mt-2">
					<div v-for="(header, index) in headersCount" :key="index" class=" mb-5 rounded-lg shadow-sm bg-white max-w-xl m-auto flex flex-col">
						<div class="flex justify-between items-center px-5 py-3 text-gray-700 border-b">
							<svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
								<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 8h16M4 16h16" />
							</svg>
							<button @click="removeHeader(index)">
								<svg
									class="h-4 w-4"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
								>
									<path
										stroke-linecap="round"
										stroke-linejoin="round"
										stroke-width="2"
										d="M6 18L18 6M6 6l12 12"
									/>
								</svg>
							</button>
						</div>
						<!-- class="flex flex-col px-5 pb-2 bg-gray-200 text-gray-700 border-b" -->
						<div class="flex flex-col my-5 px-4 pb-2 ">
							<label class="text-xs text-left">Header Title</label>
							<input
								type="text"
								v-model="headerNames[index]"
								class="form-input mt-2 w-full px-3 py-2 appearance-none rounded-md focus:border-indigo-600"/>
						</div>
						<div class="flex flex-col px-4 mb-10 text-gray-700">
							<label class="text-xs text-left">Type</label>
							<div class="mt-2 relative rounded-md shadow-sm">
								<div class="relative">
									<select
									v-model="headerTypesFront[index]"
									@change="checkValidation"
										class="appearance-none h-full rounded border block appearance-none w-full bg-white border-gray-500 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
									>
										<option v-for="(type, index) in typeOptions" :key="`type${index}`">{{type}}</option>
									</select>
								</div>
							</div>
					</div>
					</div>
					<button @click="addNewHeader" class="mt-3 px-3 py-1 bg-red-600 text-white rounded-md text-sm hover:bg-red-500 focus:outline-none">add header</button>
			</div>
		</div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { defineComponent } from 'vue';

export default defineComponent ({
	name: 'ModalSectionLayoutConfigTable',
	props: {
		submitReq: {
			type: Boolean
		}
	},
	data: () => {
		return {
			select_all: false,
			headersCount: [],
			headerNames: [],
			headerTypes: [],
			headerTypesFront: [],
			typeOptions: [
				'question title',
				'question description',
				'question help_text',
				'first option',
				'second option'
			]
			// typeOptions: [
			// 	'question.title',
			// 	'question.description',
			// 	'question.help_text',
			// 	'option.0',
			// 	'option.1'
			// ]
		}
	},
	components: {
	},
	computed: {
		...mapGetters([
		])
	},
	watch: {
		submitReq(value) {
			if (value === true) {
				let info = {
					'names': this.headerNames,
					'types': this.headerTypes,
					'select_all': this.select_all
				}
				this.$emit('tableData', info)
			}
		},
		headerNames: {
			deep: true,
			handler () {
				this.checkValidation()
			}
		},
		headerTypesFront: {
			deep: true,
			handler () {
				if (this.headerTypesFront.length) {
					for (let x = 0; x < this.headerTypesFront.length; x++) {
						if (this.headerTypesFront[x] === 'question title') {
							this.headerTypes[x] = 'question.title'
						} else if (this.headerTypesFront[x] === 'question description') {
							this.headerTypes[x] = 'question.description'
						} else if (this.headerTypesFront[x] === 'question help text') {
							this.headerTypes[x] = 'question.help_text'
						} else if (this.headerTypesFront[x] === 'first option') {
							this.headerTypes[x] = 'option.0'
						} else if (this.headerTypesFront[x] === 'second option') {
							this.headerTypes[x] = 'option.1'
						}
					}
				}
			}
		},
		headerTypes: {
			deep: true,
			handler () {
				this.checkValidation()
			}
		},
		headersCount: {
			deep: true,
			handler () {
				this.checkValidation()
			}
		}
	},
	created () {},
	mounted () {},
	methods: {
		...mapMutations([]),
		...mapActions([]),
		addNewHeader: function () {
			let info = {}
			this.headersCount.push(info)
		},
		removeHeader: function (index) {
			this.headersCount.splice(index, 1)
			this.headerNames.splice(index, 1)
			this.headerTypes.splice(index, 1)
		},
		checkValidation: function () {
			let throwError = false
			for (let x = 0; x < this.headersCount.length; x++) {
				if (this.headerNames[x]) {
					if (this.headerNames[x] === '') {
						throwError = true
					}
				} else {
					throwError = true
				}
				if (this.headerTypes[x]) {
					if (this.headerTypes[x] === '') {
						throwError = true
					}
				} else {
					throwError = true
				}
				if (x === this.headersCount.length - 1) {
					this.$emit('validationCheck', throwError)
				}
			}
		}
	}
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.toggle__dot {
  top: -.25rem;
  left: -.25rem;
  transition: all 0.3s ease-in-out;
}

input:checked ~ .toggle__dot {
  transform: translateX(100%);
  background-color: #dc2626;
}
</style>
