<template>
	<div class="flex flex-col px-5 py-6 bg-gray-200 text-gray-700 border-b">
		<label class="text-xs text-left">Headers</label>
		<div class="mt-2">
				<div v-for="(header, index) in headers" :key="index" class=" mb-5 rounded-lg shadow-sm bg-white max-w-xl m-auto flex flex-col">
					<div class="flex justify-between items-center px-5 py-3 text-gray-700 border-b">
						<svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
							<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 8h16M4 16h16" />
						</svg>
						<button v-if="getIsActiveFormEditable" @click="deleteHeader(index)">
							<svg
								class="h-4 w-4"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
							>
								<path
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
									d="M6 18L18 6M6 6l12 12"
								/>
							</svg>
						</button>
					</div>
					<div class="px-5 my-5 flex flex-col">
						<label class="text-xs text-left">Header Title</label>
						<div class="mt-2 relative rounded-md shadow-sm">
							<input
								type="text"
								v-model="headers[index].name"
								:disabled="!getIsActiveFormEditable"
								class="form-input w-full px-3 py-2 appearance-none rounded-md focus:border-indigo-600"
							/>
						</div>
					</div>
					<div class="px-5 my-5 flex flex-col">
						<label class="text-xs text-left">Header Type</label>
						<div class="mt-2 relative rounded-md shadow-sm">
							<div class="relative">
								<select
								v-model="headersFront[index]"
								:disabled="!getIsActiveFormEditable"
									class="appearance-none h-full rounded border block appearance-none w-full bg-white border-gray-500 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
								>
									<option v-for="(type, index) in typeOptions" :key="`option${index}`">{{type}}</option>
								</select>
							</div>
						</div>
					</div>
				</div>
				<div v-for="(newHeader, index) in newHeaders" :key="`newheader${index}`" class=" mb-5 rounded-lg shadow-sm bg-white max-w-xl m-auto flex flex-col">
					<div class="flex justify-between items-center px-5 py-3 text-gray-700 border-b">
						<svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
							<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 8h16M4 16h16" />
						</svg>
						<button v-if="getIsActiveFormEditable" @click="removeNewHeader(index)" :disabled="!getIsActiveFormEditable">
							<svg
								class="h-4 w-4"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
							>
								<path
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
									d="M6 18L18 6M6 6l12 12"
								/>
							</svg>
						</button>
					</div>
					<div class="px-5 my-5 flex flex-col">
						<label class="text-xs text-left">Header Title</label>
						<div class="mt-2 relative rounded-md shadow-sm">
							<input
								type="text"
								v-model="newHeader.name"
								:disabled="!getIsActiveFormEditable"
								class="form-input w-full px-3 py-2 appearance-none rounded-md focus:border-indigo-600"
							/>
						</div>
					</div>
					<div class="px-5 my-5 flex flex-col">
						<label class="text-xs text-left">Header Type</label>
						<div class="mt-2 relative rounded-md shadow-sm">
							<div class="relative">
								<select
								v-model="newHeadersFront[index]"
								:disabled="!getIsActiveFormEditable"
									class="appearance-none h-full rounded border block appearance-none w-full bg-white border-gray-500 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
								>
									<option v-for="(type, i) in typeOptions" :key="`option${i}`">{{type}}</option>
								</select>
							</div>
						</div>
					</div>
				</div>
				<button
					:disabled="!totalHeadersValid || !getIsActiveFormEditable"
					@click="addNewHeader()"
					:class="!totalHeadersValid ? 'bg-red-200': 'bg-red-600 hover:bg-red-500'"
					class="mt-3 px-3 py-1 text-white rounded-md text-sm focus:outline-none">add header</button>
		</div>
	</div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { defineComponent } from 'vue';

export default defineComponent ({
	name: 'ModalSectionEditTable',
	props: {},
	data: () => {
		return {
			headers: [],
			headersFront: [],
			newHeaders: [],
			newHeadersFront: [],
			totalHeaders: [],
			// typeOptions: [
			// 	'question.title',
			// 	'question.description',
			// 	'question.help_text',
			// 	'option.0',
			// 	'option.1'
			// ],
			typeOptions: [
				'question title',
				'question description',
				'question help_text',
				'first option',
				'second option'
			],
			headersValid: true,
			newHeadersValid: true,
			deletedOriginalHeader: false
		}
	},
	components: {
	},
	computed: {
		...mapGetters([
			'getModifyingSection',
			'getIsActiveFormEditable'
		]),
		addingNewHeader() {
			if (this.newHeaders.length && this.newHeaders.length > 0) {
				return true
			} else {
				return false
			}
		},
		totalHeadersValid() {
			return (this.headersValid === true && this.newHeadersValid === true) || this.deletedOriginalHeader === true
		}
	},
	watch: {
		headers: {
			deep: true,
			handler () {
				let valid = true
				for (let x = 0; x < this.headers.length; x++) {
					if (this.headers[x].name === '' || this.headers[x].type === '') {
						valid = false
					}
					if (x === this.headers.length - 1) {
						this.headersValid = valid
					}
				}
			}
		},
		headersFront: {
			deep: true,
			handler () {
				if (this.headersFront.length) {
					for (let x = 0; x < this.headersFront.length; x++) {
						if (this.headersFront[x] === 'question title') {
							this.headers[x].type = 'question.title'
						} else if (this.headersFront[x] === 'question description') {
							this.headers[x].type = 'question.description'
						} else if (this.headersFront[x] === 'question help text') {
							this.headers[x].type = 'question.help_text'
						} else if (this.headersFront[x] === 'first option') {
							this.headers[x].type = 'option.0'
						} else if (this.headersFront[x] === 'second option') {
							this.headers[x].type = 'option.1'
						}
					}
				}
			}
		},
		newHeadersFront: {
			deep: true,
			handler () {
				if (this.newHeadersFront.length) {
					for (let x = 0; x < this.newHeadersFront.length; x++) {
						if (this.newHeadersFront[x] === 'question title') {
							this.newHeaders[x].type = 'question.title'
						} else if (this.newHeadersFront[x] === 'question description') {
							this.newHeaders[x].type = 'question.description'
						} else if (this.newHeadersFront[x] === 'question help text') {
							this.newHeaders[x].type = 'question.help_text'
						} else if (this.newHeadersFront[x] === 'first option') {
							this.newHeaders[x].type = 'option.0'
						} else if (this.newHeadersFront[x] === 'second option') {
							this.newHeaders[x].type = 'option.1'
						}
					}
				}
			}
		},
		newHeaders: {
			deep: true,
			handler () {
				let valid = true
				if (this.newHeaders.length && this.newHeaders.length > 0) {
					for (let x = 0; x < this.newHeaders.length; x++) {
						if (this.newHeaders[x].name === '' || this.newHeaders[x].type === '') {
							valid = false
						}
						if (x === this.newHeaders.length - 1) {
							this.newHeadersValid = valid
						}
					}
				} else {
					this.newHeadersValid = valid
				}
			}
		},
		totalHeadersValid(value) {
			this.emitHeaders(value)
		}
	},
	created () {
		this.headers = JSON.parse(JSON.stringify(this.getModifyingSection.layout_config.table_headers))
		if (this.headers.length) {
			for (let x = 0; x < this.headers.length; x++) {
				if (this.headers[x].type === 'question.title') {
					this.headersFront[x] = 'question title'
				} else if (this.headers[x].type === 'question.description') {
					this.headersFront[x] = 'question description'
				} else if (this.headers[x].type === 'question.help_text') {
					this.headersFront[x] = 'question help text'
				} else if (this.headers[x].type === 'option.0') {
					this.headersFront[x] = 'first option'
				} else if (this.headers[x].type === 'option.1') {
					this.headersFront[x] = 'second option'
				} else {
					this.headersFront[x] = this.headers[x].type
				}
			}
		}
		this.emitHeaders(true)
	},
	mounted () {
	},
	methods: {
		...mapMutations([
			'setModifyingSection'
		]),
		...mapActions([]),
		deleteHeader: function (index) {
			this.headers.splice(index, 1)
			this.deletedOriginalHeader = true
		},
		emitHeaders: function (value) {
			let payload = {
				'valid': value,
				'headers': []
			}
			if (value === true) {
				this.totalHeaders = this.headers.concat(this.newHeaders)
				payload.headers = this.totalHeaders
				this.$emit('something', payload)
			} else {
				this.$emit('something', payload)
			}
		},
		removeNewHeader: function (index) {
			this.newHeaders.splice(index, 1)
		},
		addNewHeader: function () {
			this.newHeaders.push({
				'name': '',
				'type': ''
			})
		}
	}
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
