<template>
	<div>
		<div class="flex flex-col  pb-6 text-gray-700">
			<div class="mt-2 relative rounded-md shadow-sm">
				<div class="relative flex flex-col mt-2">
					<label class="text-xs text-left">Currency</label>
					<select
					v-model="currency"
					:disabled="!getIsActiveFormEditable"
					class="mt-2 appearance-none h-full rounded border block appearance-none w-full bg-white border-gray-500 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 disabled:opacity-60"
					>
						<option v-for="(option, index) in currencies" :key="`option${index}`">{{option}}</option>
					</select>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { defineComponent } from 'vue';

export default defineComponent ({
	name: 'OptionFieldCurrency',
	props: {
		clear: {
			type: Boolean
		},
		currentlyEditing: {
			type: Object
		},
		addNew: {
			type: Boolean
		},
		index: {
			type: Number
		}
	},
	data: () => {
		return {
			currency: '',
			currencies: [
				'CAD',
				'USD'
			]
		}
	},
	components: {},
	computed: {
		...mapGetters([
			'getActiveQuestionId',
			'getIsEdit',
			'getIsActiveFormEditable'
		])
	},
	watch: {
		currency(value) {
			this.updateCurrency(value)
		},
		clear(value) {
			if (value === true) {
				this.currency = ''
			}
		}
	},
	created () {
		this.$emit('validate', false)
		if (this.getIsEdit) {
			if (!this.addNew && this.currentlyEditing) {
				this.currency = this.currentlyEditing.config.currency_type
				this.$emit('validate', true)
			}
		}
	},
	mounted () {},
	methods: {
		...mapMutations([
			'setModalContent',
			'setShowModal',
			'closeModal',
			'setWorkingOption',
			'modifyActiveOption'
		]),
		...mapActions([
		]),
		updateCurrency: function (value) {
			let data = {
				'question_id': this.getActiveQuestionId,
				'type': 'currency',
				'order': 1,
				'config': {
					'currency_type': value,
				}
			}
			this.$emit('validate', true)
			this.setWorkingOption(data)
			if (this.getIsEdit && this.currentlyEditing) {
				data.id = this.currentlyEditing.id
				data.check = true
				let info = {
					'index': this.index,
					'modified': data
				}
				this.modifyActiveOption(info)
			}
		}
	}
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.toggle__dot {
  top: -.25rem;
  left: -.25rem;
  transition: all 0.3s ease-in-out;
}

input:checked ~ .toggle__dot {
  transform: translateX(100%);
  background-color: #dc2626;
}
</style>
